import { useEffect, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import { ThemeProvider } from 'baseui'
import { useSelector } from 'react-redux'
import { selectFonts } from '@/store/slices/fonts/selectors'
import { IFontFamily } from '@/interfaces/editor'
import HeadingInspector from '@/components/HeadingInspector'
import { lightTheme } from '@/customTheme'
import AutoScroll from '@/components/AutoScroll'
import groupBy from 'lodash/groupBy'
import { styled } from 'baseui'
import { TextOptions } from 'fabric/fabric-impl'
import { useStyletron } from 'baseui'
import api from '@/services/api'
import { ObjectType } from '@/scenes/engine/common/constants'
import Icons from '../../Icons'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useAppDispatch } from '@/store/store'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'

function FontFamily({ isOpenFontFamily, setIsOpenFontFamily }: { isOpenFontFamily?: boolean; setIsOpenFontFamily?: Function }) {
  const { activeObject, canvas } = useEditorContext()
  const [value, setValue] = useState('')
  const fonts = useSelector(selectFonts)

  const fontGroups = groupBy(fonts, 'category')

  const [fontGroupByPacks, setFontGroupByPacks] = useState({})
  const [preFont, setPreFont] = useState({})

  const [userLanguages, setUserLanguages] = useState(navigator.languages || [navigator.language])
  
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if(userLanguages && !userLanguages.includes(i18n.language)) {
      setUserLanguages(pre => [...pre, i18n.language])
    }
  }, [i18n.language])
  useEffect(() => {
    groupFont()
  }, [])

  const groupFont = async () => {
    let localFontPacks = await api.getFontPacks()
    // @ts-ignore
    localFontPacks = localFontPacks.filter(font => !font.locale || userLanguages.some(lang => lang.startsWith(font.locale)))

    const category = localFontPacks.map(f => ({
      name: f.name,
      // @ts-ignore
      is_free: f.is_free,
    }))
    let fontGroupBy = {}
    category.forEach(c => {
      if (fontGroups[c.name]) {
        fontGroupBy = {
          ...fontGroupBy,
          [c.name]: fontGroups[c.name].map(f => {
            return {
              ...f,
              is_free: c.is_free,
            }
          }),
        }
      }
    })
    setFontGroupByPacks(fontGroupBy)
  }

  const editor = useEditorContext().editor
  const [isBoxShadow, setIsBoxShadow] = useState(false)

  useEffect(() => {
    setValue((activeObject as TextOptions).fontFamily)
    let fontValue = {
      name: activeObject.name,
      //@ts-ignore
      fontFamily: activeObject.fontFamily,
      // @ts-ignore
      // originFontFamily: activeObject.originFontFamily,
    }
    setPreFont(fontValue)
  }, [activeObject])

  const fontOverLeave = (font: IFontFamily) =>{
    if(font.family === value) return
    let sliderOptions: any = { isSliderUpdate: false }
    editor.handlers.objectsHandler.updateActive(preFont, null, sliderOptions)
  }

  const handleFontFamilyChange = async (fontFamily: IFontFamily, isPreview = false) => {
    if(isPreview && !canChangeFont) return
    if (editor) {
      !isPreview && setValue(fontFamily.family)
      //TODO: send analytics - Selected tool text.font
      const fontFile = fontFamily.files['regular' as any]
      const font = {
        name: fontFamily.name,
        family: fontFamily.family,
        url: fontFile,
        options: { style: 'normal', weight: 400 },
      }
      
      // @ts-ignore
      const fontFace = new FontFace(font.family, `url(${font.url})`, font.options)
      fontFace
        .load()
        .then(loadedFont => {
          document.fonts.add(loadedFont)
          fontFace.loaded.then(() => {

            let fontValue = {
              name: fontFamily.name,
              fontFamily: fontFamily.family,
              // @ts-ignore
              // originFontFamily: fontFamily.family,
              metadata: {
                fontURL: font.url,
              },
            }
            let sliderOptions: any = { isSliderUpdate: !isPreview }
            if (!isPreview) {
              sliderOptions = { isSliderUpdate: !isPreview, property: Object.keys(preFont), value: Object.values(preFont) }
              setPreFont(fontValue)
              const eventProperties = {
                Tool: 'bazaart.text.font',
                'Layer Type': ObjectType.BAZAART_TEXT,
              }
              customAmplitude('Selected tool', eventProperties)
            }
            editor.handlers.objectsHandler.updateActive(fontValue, null, sliderOptions)
          })
        })
        .catch(err => console.log(err))
    }
  }

  const [canChangeFont, setCanChangeFont] = useState(true)
  console.log('canChangeFont', canChangeFont);
  
  useEffect(() => {
    if(!canvas) { return }
    if(isOpenFontFamily) {
      editor.on('object:changing', () => {
        setCanChangeFont(false)
      })
      canvas.on('object:modified', () => {
        setCanChangeFont(true)
      })
      editor.on('text:moved', () => {
        setCanChangeFont(true)
      })
    }
    return () => {
      editor.off('object:changing', () => {
        setCanChangeFont(false)
      })
      editor.on('text:moved', () => {
        setCanChangeFont(true)
      })
      canvas.off('object:modified', () => {
        setCanChangeFont(true)
      })
    }
  }, [canvas, isOpenFontFamily, editor])

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          width: isOpenFontFamily ? '340px' : '0',
          overflow: 'hidden',
          borderRadius: '12px',
          position: 'absolute',
          top: '0px',
          bottom: '0px',
          right: '0px',
          background: '#fff',
          zIndex: 1,
          // transition: 'all .4s ease-in',
        }}
      >
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
          <HeadingInspector
            hasBoxShadow={isBoxShadow}
            hasNavigation={true}
            handleNavigation={() => setIsOpenFontFamily(false)}
            title={t('Fonts')}
          ></HeadingInspector>
          {/* <Input
            startEnhancer={() => <Icons.Search size={18} />}
            value={value}
            onChange={e => setValue((e.target as any).value)}
            placeholder="Search font"
            clearOnEscape
          /> */}
          <AutoScroll
            handleScroll={e => {
              if (e.target.scrollTop > 0) {
                setIsBoxShadow(true)
              } else {
                setIsBoxShadow(false)
              }
            }}
          >
            {/* <Search handleValueChange={setValue} size="large" /> */}
            <div style={{ display: 'grid'}}>
              {/* {fonts.map(font => (
                <div
                  className="font-item"
                  onClick={() => handleFontFamilyChange(font)}
                  key={font.id}
                  // f={font}
                >
                  <div className="font-item name">{font.name}</div>
                  <div className="font-item desc">{font.desc}</div>
                </div>
              ))} */}
              {Object.keys(fontGroupByPacks).map((group, index) => {
                return (
                  <FontGroup
                    current={value}
                    name={group}
                    key={group}
                    fontGroup={fontGroupByPacks[group]}
                    handleFontFamilyChange={handleFontFamilyChange}
                    onFontOverLeave={fontOverLeave}
                    style={index === 0 ? {marginTop: 0} : {}}
                  />
                )
              })}
            </div>
          </AutoScroll>
          {/* <div style={{ flex: 1 }}>
            <Scrollbars>
              <div style={{ display: 'grid' }}>
                {fonts.map(font => (
                  <FontItem onClick={() => handleFontFamilyChange(font)} key={font.id} f={font}>
                    {font.family}
                  </FontItem>
                ))}
              </div>
            </Scrollbars>
          </div> */}
        </div>
        {/* TODO */}
        {/* <div
          style={{
            boxSizing: 'border-box',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '88px',
            borderTop: '1px solid rgba(229, 229, 229, 1)',
            background: 'white',
            padding: '19px 24px 20px',
          }}
        >
          <ButtonCustom
            kind={KIND.primary}
            type={SizeButton.LARGE}
            onClick={() => {}}
            style={{ color: 'white' }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}>
              <UploadsIcon size={24} />
              <p style={{ fontWeight: 600, fontSize: '14px' }}>{'Upload fonts'}</p>
            </div>
          </ButtonCustom>
        </div> */}
      </div>
    </ThemeProvider>
  )
}

function FontGroup({ current, fontGroup, name, handleFontFamilyChange, style = {}, onFontOverLeave }) {
  const [max, setMax] = useState(10)
  const [showMore, setShowMore] = useState(true)
  const [css, theme] = useStyletron()

  useEffect(() => {
    if (fontGroup.length < max) {
      setShowMore(false)
    }
  }, [])

  const { t } = useTranslation()

  return (
    <div
      style={{
        boxSizing: 'border-box',
        width: '292px',
        ...style
      }}
      className='mt-50'
    >
      <div
        style={{
          boxSizing: 'border-box',
          width: '100%',
          height: '26px',
          marginBottom: '8px',
          color: '#666666',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className="name" style={{...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title }}>
          {name}
        </div>
        {showMore && (
          <div
            className={css({
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '24px',
              cursor: 'pointer',
              padding: '0 8px',
              borderRadius: '12px',
              ':hover': {
                background: 'rgb(229, 229, 229)',
              },
              ...lightTheme.typography.Small12medium
            })}
            onClick={() => {
              setMax(fontGroup.length)
              setShowMore(false)
            }}
          >
            {t('Show more')}
          </div>
        )}
        {fontGroup.length > 4 && !showMore && (
          <div
            className="action"
            style={{
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '24px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setMax(10)
              setShowMore(true)
            }}
          >
            {t('Show Less')}
          </div>
        )}
      </div>
      <div
        className="group-font-item  "
        style={{
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          gridColumnGap: '8px',
          gridRowGap: '8px',
        }}
      >
        {fontGroup.map(
          (font, index) =>
            index < max && (
              <FontItem
                font={font}
                selected={current === font.family}
                onClick={handleFontFamilyChange}
                key={font.id}
                index={index}
                onMouseEnter={handleFontFamilyChange}
                onMouseLeave={onFontOverLeave}
              ></FontItem>
            )
        )}
      </div>
    </div>
  )
}

export const FontItem = ({ font, selected, onClick, index, onMouseEnter ,onMouseLeave }) => {
  const { activeObject, canvas } = useEditorContext()
  const dispatch = useAppDispatch()
  const WrapFontItem = styled('div', (props: { f: IFontFamily; selected: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 12px',
    width: '142px',
    height: '56px',
    background: 'none',
    borderRadius: '8px',
    borderWidth: props.selected ? '2px' : '1px',
    borderStyle: 'solid',
    borderColor: props.selected ? lightTheme.colors.primary : lightTheme.colors.buttonFontBorder,
    cursor: 'pointer',
    ':hover': {
      background: props.selected ? 'none' : '#f2f2f2',
      borderColor: props.selected ? lightTheme.colors.primary : lightTheme.colors.buttonFontBorderHover,
    },
  }))
  useEffect(() => {
    const fontFace = new FontFace(font.family, `url(${font.files['regular' as any]})`, {
      style: 'normal',
      weight: '400',
    })
    fontFace.load().then(loadedFont => {
      document.fonts.add(loadedFont)
    })
  }, [])

  const [css] = useStyletron()
  const hasPremium = useSelector(selectUserIsPremium) || font.is_free
  let isLocked = !hasPremium && index >= 3
  return (
    <WrapFontItem
      f={font}
      selected={selected}
      onClick={() => {
        if (isLocked) {
          const eventProperties = {
            Source: 'BtSubscriptionFonts',
            Type: 'Standard',
          }
          customAmplitude('Premium Prompt', eventProperties)
          // @ts-ignore
          window.dataLayer.push({event: 'premium_prompt',...eventProperties});
          dispatch(setOpenModalTryPremium({
            isOpen: true,
            source: 'BtSubscriptionFonts',
            callback: () => onClick(font)
          }))
          onMouseLeave(font)
          return
        }
        onClick(font)
      }}
      onMouseEnter={() => {
        onMouseEnter(font, true)
      }}
      onMouseLeave={() => {
        onMouseLeave(font)
      }}
    >
      <div
        className={css({
          fontWeight: 500,
          fontSize: lightTheme.typography.Small14Semibold.fontSize,
          lineHeight: lightTheme.typography.Small14Semibold.lineHeight,
          color: selected ? lightTheme.colors.primary : lightTheme.colors.grayScale600,
          textAlign: 'center',
          width: '100%',
          fontFamily: font.family,
        })}
      >
        {font.name}
      </div>
      <div
        className={css({
          ...lightTheme.typography.Small10bold,
          lineHeight: '12px',
          color: lightTheme.colors.grayScale300,
          textAlign: 'center',
          width: '100%',
          textTransform: 'uppercase',
        })}
      >
        {font.desc}
      </div>
      {isLocked ? (
        <div style={{ position: 'absolute', top: '4px', right: '4px', zIndex: 1 }}>
          <Icons.PremiumIcon />
        </div>
      ) : null}
    </WrapFontItem>
  )
}
export default FontFamily