import ButtonCustom from '@/components/ButtonCustom'
import Loading from '@/components/Loading'
import { SizeButton } from '@/constants/sizeButton'
import { useEditorContext } from '@/scenes/engine'
import { KIND } from 'baseui/button'
import React, { useCallback, useEffect, useState } from 'react'
import Icons from '../../../Icons'
import { ObjectType } from '@/scenes/engine/common/constants'
import { lightTheme } from '@/customTheme'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
const LIST_TRANSFORM = [
  {
    id: 1,
    name: 'Flip',
    nameIcon: 'FlipH',
  },
  {
    id: 2,
    name: 'Mirror',
    nameIcon: 'FlipV',
  },
  // {
  //   id: 3,
  //   name: 'Stretch',
  //   nameIcon: 'Stretch',
  // },
  // {
  //   id: 4,
  //   name: 'Distort',
  //   nameIcon: 'Distort',
  // },
]

function Transform({ style }: { style?: React.CSSProperties }) {
  const { activeObject } = useEditorContext()
  const editor = useEditorContext().editor

  const [indexActive, setIndexActive] = useState()
  const [state, setState] = useState({ flipX: false, flipY: false })

  useEffect(() => {
    updateOptions(activeObject)
  }, [activeObject])

  useEffect(() => {
    const handleChanges = () => {
      updateOptions(activeObject)
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  const getTransformIcon = value => {
    // const currentValue = activeObject.textAlign
    const Icon =
      value === 'FlipH'
        ? Icons.FlipH
        : value === 'FlipV'
        ? Icons.FlipV
        : value === 'Stretch'
        ? Icons.Stretch
        : Icons.Distort
    return Icon
  }

  const handleSelectItem = index => {
    if (indexActive !== index) {
      setIndexActive(index)
    } else {
      setIndexActive(null)
    }
  }
  // flip
  const flipHorizontally = () => {
    const eventProperties = {
      Tool: 'bazaart.flip',
      'Layer Type': activeObject.type,
    }
    customAmplitude('Selected tool', eventProperties)

    editor.handlers.objectsHandler.update({ flipX: !state.flipX })
    setState(pre => ({...pre, flipX: !pre.flipX}))
  }
  const flipVertically = () => {
    const eventProperties = {
      Tool: 'bazaart.verticalFlip',
      'Layer Type': activeObject.type,
    }
    customAmplitude('Selected tool', eventProperties)
    editor.handlers.objectsHandler.update({ flipY: !state.flipY })
    setState(pre => ({...pre, flipY: !pre.flipY}))
  }
  const updateOptions = (object: fabric.IObjectOptions) => {
    setState({ flipX: object.flipX, flipY: object.flipY })
  }

  const { t } = useTranslation()
  return (
    <div style={{ width: '300px', paddingRight: '8px', marginRight: '16px', ...style }} className="mt-50">
      <div
        style={{
          width: '292px',
          height: '26px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <span style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title, textTransform: 'capitalize' }}>{t('Transform')}</span>
      </div>
      {LIST_TRANSFORM && LIST_TRANSFORM.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '8px', width: '292px', rowGap: '20px' }}>
          {LIST_TRANSFORM.map((i, index) => {
            let IconTransform = getTransformIcon(i.nameIcon)

            return (
              <div key={index}>
                <ButtonCustom
                  type={SizeButton.LARGE}
                  kind={KIND.tertiary}
                  onClick={index === 0 ? flipHorizontally : index === 1 ? flipVertically : () => {}}
                  style={{ width: '67px', position: 'relative' }}
                >
                  <IconTransform size={24} />
                  <p
                    style={{
                      position: 'absolute',
                      margin: 0,
                      top: `calc(100% + 8px)`,
                      left: 0,
                      right: 0,
                      textAlign: 'center',
                      fontSize: lightTheme.typography.Small11medium.fontSize,
                      fontWeight: lightTheme.typography.Small11medium.fontWeight,
                      lineHeight: lightTheme.typography.Small11medium.lineHeight,
                      color: lightTheme.colors.grayScale600,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {t(i.name)}
                  </p>
                </ButtonCustom>
              </div>
            )
          })}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default Transform
