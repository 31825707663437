import React, { useState, useRef, useCallback } from 'react'
import HeadingInspector from '@/components/HeadingInspector'
// import Search from '@/components/icons/Search'
import { lightTheme } from '@/customTheme'
import { ThemeProvider } from 'baseui'
import PackContainer from '@/PackContainer'
import { useSelector } from 'react-redux'
import { selectBackgroundPacks } from '@/store/slices/backgroundPacks/selectors'
import AutoScroll from '@/components/AutoScroll'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useTranslation } from 'react-i18next'
import Search from '../../Toolbox/ToolboxItems/components/Search'
import { debounce } from 'lodash'
import Loading from '@/components/Loading'
import { searchPhotosUnplash } from '@/services/connect_unsplash'
import { useStyletron } from 'styletron-react'
import { customAmplitude } from '@/utils/customAmplitude'
import { PackItem } from '@/components/Gallery/Gallery'
import { styled } from 'baseui'

const Outline1 = styled('div', (props: { $active: boolean }) => ({
  position: 'absolute',
  inset: 0,
  borderRadius: '8px',
  border: `2px solid ${props.$active ? '#FF0560' : 'transparent'}`,
}))
const Outline2 = styled('div', (props: { $active: boolean }) => ({
  position: 'absolute',
  inset: '2px',
  borderRadius: '6px',
  border: `2px solid ${props.$active ? '#fff' : 'transparent'}`,
}))

function Fill({
  isOpen,
  setIsOpenFill,
  bgActiveID,
  handleSelectBgImage,
  isFocusSearchBox,
  loadBackgroundImage
}: {
  isOpen: boolean
  setIsOpenFill: Function
  bgActiveID: string
  handleSelectBgImage: Function,
  isFocusSearchBox: boolean,
  loadBackgroundImage: Function
}) {
  const backgroundPacks = useSelector(selectBackgroundPacks)
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  const { t } = useTranslation()
  //
  const [defaultSection, setDefaultSection] = useState(10)
  const listBackground = useRef(null)
  const hasPremium = useSelector(selectUserIsPremium)
  const [searchValue, setSearchValue] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [resultSearch, setResultSearch] = useState(null)
  const debouncedSearch = 200
  const searchAbortController = useRef(null);
  const [defaultPageForSearch, setDefaultPageForSearch] = useState(1)

  // useEffect(() => {
  //   if (editor) {
  //     if (activeObject && activeObject.type === ObjectType.BAZAART_STICKER) {
  //       // set background active for STICKER
  //       // setBgActiveID(() => ....)
  //     }
  //     if (!activeObject && editor.handlers.frameHandler.getBackgroundImage()) {
  //       const currentBackgroundImageJSON = editor.handlers.frameHandler.getBackgroundImage().toJSON()

  //       // @ts-ignore
  //       // setBgActiveID(() => currentBackgroundImageJSON.id)
  //     }
  //   }
  // }, [editor, isOpen, activeObject])

  const renderRow = ({ index, style }) => {
    return (
      <PackContainer
        style={{
          ...style,
          paddingTop: '0',
          height: 'fit-content',
          boxSizing: 'border-box',
          marginTop: index === 0 ? '0' : null,
        }}
        pack={backgroundPacks[index]}
        onItemSelected={handleSelectBgImage}
        bgActiveID={bgActiveID}
        itemsPerLine={5}
        hasPremium={hasPremium || backgroundPacks[index].is_free}
      />
    )
  }

  const handleReset = () => {
    const reset = () => {
      if (searchAbortController.current) {
        searchAbortController.current.abort()
      }
      setDefaultPageForSearch(1)
      setResultSearch(null);
      handleSearch('')
      setSearchValue('')
    }
    setTimeout(() => {
      reset()
    }, debouncedSearch);

  }

  const handleSearch = async value => {
    if (!value) {
      setResultSearch(null)
      setIsSearching(false)
      return;
    }
    setIsSearching(true)

    if (searchAbortController.current) {
      searchAbortController.current.abort()
    }

    searchAbortController.current = new AbortController()
    const { signal } = searchAbortController.current

    try {
      const data = await searchPhotosUnplash(value, 1, signal);
      setResultSearch(data)
      
      const eventProperties = {
        Query: value,
      }
      setIsSearching(false);
      if(value && value.length > 1) {
        customAmplitude('Background search', eventProperties)
      }
    } catch (error) {
      if (error.name !== 'CanceledError') {
        console.error("Error during search:", error);
        setResultSearch(null);
      }
    }
  }

  const debounceHandleSearch = useCallback(debounce(value => {
    handleSearch(value)
  }, debouncedSearch), [])
  
  const debounceGetMoreData = debounce(() => {
    getMoreData()
  }, debouncedSearch)

  const getMoreData = async () => {
    let data
    if (resultSearch) {
      if(resultSearch.length === 0) return
      setIsSearching(true)
      data = await searchPhotosUnplash(searchValue, defaultPageForSearch + 1)
      if (data?.length > 0) {
        setResultSearch(pre => pre ? [...pre, ...data] : data)
        setDefaultPageForSearch(pre => pre + 1)
      }
      setIsSearching(false)
    }
  }
  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          width: '340px',
          overflow: 'hidden',
          position: 'absolute',
          top: '0px',
          bottom: '16px',
          right: isOpen ? '0px' : '-356px',
          borderRadius: '12px',
          background: '#fff',
          zIndex: 1,
          // transition: 'all .4s ease-in',
          height: '100%',
        }}
      >
        <HeadingInspector
          hasNavigation={true}
          handleNavigation={() => setIsOpenFill(false)}
          hasBoxShadow={isBoxShadow}
          title={t("Fill")}
        ></HeadingInspector>
         <div
            style={{
              padding: '0 24px 12px 24px',
              paddingTop: 0,
              width: '100%',
              boxSizing: 'border-box',
              background: '#fff',
              zIndex: 1000,
            }}
          >
            <Search
              handleValueChange={debounceHandleSearch}
              size="standard"
              valueExternal={searchValue}
              setValueExternal={setSearchValue}
              handleReset={() => handleReset()}
              focusSearchBox={isFocusSearchBox}
            />
          </div>
        <AutoScroll
          ref={listBackground}
          handleScroll={e => {
            setIsBoxShadow(e.target.scrollTop > 0)
            if (!resultSearch &&
              Math.round(listBackground.current.scrollTop) + listBackground.current.clientHeight ===
              listBackground.current.scrollHeight
            ) {
              setDefaultSection(pre => pre + 10)
            }
            if (resultSearch && 
              Math.round(listBackground.current.scrollTop) + listBackground.current.clientHeight >=
              listBackground.current.scrollHeight - 1 &&
              Math.round(listBackground.current.scrollTop) + listBackground.current.clientHeight <=
              listBackground.current.scrollHeight + 1
            ) {
              debounceGetMoreData()
            }
          }}
        >
          
          {(!resultSearch) && !isSearching && (
            backgroundPacks.map((b, index) => {
              if (index < defaultSection) {
                return <div key={index}>{renderRow({ index, style: {} })}</div>
              }
              return null
            })
          )}
          {/* display search results */}
          {resultSearch && resultSearch.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
                marginTop: '8px',
              }}
            >
              {resultSearch.map((result, index) => {
               
                let isSelected = bgActiveID == result.id

                return (
                  <div style={{ display: 'flex', position: 'relative' }} key={result.id}>
                  <PackItem
                    onClick={() => {
                      loadBackgroundImage(result)
                    }}
                    style={{
                      wrap: {
                        width: '92px',
                        height: '92px',
                        padding: '0',
                        zIndex: '1',
                      },
                      placehoder: {
                        inset: 0,
                      },
                      image: {
                        objectFit: 'cover',
                      },
                    }}
                    pack={{
                      thumbnail: result?.urls?.thumb,
                      id: result.id,
                      name: result.user.name
                    }}
                    onDrag={() => {}}
                    isSelected={isSelected}
                    CTA="BtSubscriptionBackgrounds"
                  >
                    <Outline1 $active={isSelected}></Outline1>
                    <Outline2 $active={isSelected}></Outline2>
                  </PackItem>
                </div>
                  // <WrapImage item={result} handleOnClick={loadBackgroundImage}  />
                )
              })}
            </div>
          )}
          {/* No result */}
          {resultSearch && resultSearch.length === 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                flexDirection: 'column',
              }}
            >
              <p style={{ ...lightTheme.typography.LabelLarge, color: lightTheme.colors.blackGray, margin: 0 }}>
                {t('No results')}
              </p>
              <p
                style={{
                  ...lightTheme.typography.Small12regular,
                  color: 'rgba(153, 153, 153, 1)',
                  margin: 0,
                }}
              >
                {t('For \"%@\"', { arg:searchValue})}
              </p>
            </div>
          )}
          {/* Loading */}
          {isSearching ? (
            <div style={{
              position: 'absolute',
              inset: 0,
              top: '114px',
              background: '#fff'
            }}>
              <div
                style={{
                  position: 'absolute',
                  bottom: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-80%)',
                  zIndex: 1000,
                  paddingLeft: '12px',
                }}
              >
                <Loading />
              </div>    
            </div>
          ) : null}
        </AutoScroll>
        {/* </div> */}
        {/* <div
          style={{
            boxSizing: 'border-box',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '88px',
            borderTop: '1px solid rgba(229, 229, 229, 1)',
            background: 'white',
            padding: '19px 24px 20px',
          }}
        >
          <ButtonCustom
            kind={KIND.primary}
            type={SizeButton.LARGE}
            onClick={() => {}}
            style={{ color: 'white' }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}>
              <UploadsIcon size={24} />
              <p style={{ fontWeight: 600, fontSize: '14px' }}>{'Upload'}</p>
            </div>
          </ButtonCustom>
        </div> */}
      </div>
    </ThemeProvider>
  )
}

export default Fill
function WrapImage({ item, handleOnClick }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [css] = useStyletron()
  return (
    <div className={css({
      width: '92px',
      borderRadius: '8px',
      height: `92px`,
      objectFit: 'fill',
      ':hover div': {
        display: 'flex !important',
        flexDirection: 'column-reverse',
      },
      ':active div': {
        display: 'flex !important',
        flexDirection: 'column-reverse',
        background: 'rgba(0,0,0,.4) !important',
      },
      position: 'relative',
    })}>
      <img
        onClick={() => handleOnClick(item)}
        style={{
          width: '100%',
          borderRadius: '8px',
          height: `100%`,
          objectFit: 'cover',
          cursor: 'pointer',
        }}
        src={item?.urls?.thumb}
        alt={item.alt_description}
        onLoad={e => {
          setIsLoaded(true)
        }}
      />
      <div
        style={{
          position: 'absolute',
          inset: 0,
          background: !isLoaded ? lightTheme.colors.grayScale50 : 'rgba(0, 0, 0, .25)',
          display: isLoaded ? 'none' : 'block',
          zIndex: 10,
          borderRadius: '8px',
          pointerEvents: 'none',
          padding: '4px',
        }}
      >
        <span style={{ color: '#fff', fontSize: '10px', lineHeight: '12px' }}>
          {item.user.name} / Unsplash
        </span>
      </div>
    </div>
  )
}