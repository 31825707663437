import { useEffect, useState } from 'react'
import SliderCustom from '@/components/SliderCustom'
import { useEditorContext } from '@/scenes/engine'
import Icons from '../../../Icons'
import { customAmplitude } from '@/utils/customAmplitude'

import { useTranslation } from 'react-i18next'


const ITEMS = [30, 40, 48, 72, 80, 96, 120, 140, 180, 220]

function TextFontSize() {
  const activeObject = useEditorContext().activeObject as fabric.TextOptions

  const [fontSize, setFontSize] = useState(6)
  const [preFontSize, setPreFontSize] = useState(6)

  const editor = useEditorContext().editor

  useEffect(() => {
    setFontSize(Math.round(activeObject.fontSize))
    setPreFontSize(Math.round(activeObject.fontSize))
  }, [activeObject])

  useEffect(() => {
    const handleChanges = () => {
      setFontSize(Math.round(activeObject.fontSize))
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  const updateFontSize = (value: number, finalValue = false): void => {
    const sliderOptions = finalValue
      ? { isSliderUpdate: finalValue, property: ['fontSize'], value: [preFontSize] }
      : { isSliderUpdate: finalValue }

    if (finalValue) {
      setPreFontSize(value)
    }

    editor.handlers.objectsHandler.updateActive({ fontSize: value }, undefined, sliderOptions)
  }
  const { t } = useTranslation()

  return (
    <div style={{ width: '292px' }} className='mt-50'>
      <SliderCustom
        icon={<Icons.AA />}
        name={t("Size")}
        listValues={ITEMS}
        minValue={6}
        maxValue={Math.max(...ITEMS)}
        onChangeValue={value => {
          setFontSize(value)
          updateFontSize(value)
        }}
        onFinalChangeValue={value => {
          console.log('Final change', value)
          const eventProperties = {
            Tool: 'bazaart.style.fontSize',
            'Layer Type': activeObject.type,
          }
          customAmplitude('Selected tool', eventProperties)
          updateFontSize(value, true)
        }}
        value={Math.round(activeObject.fontSize) ?? fontSize}
      />
    </div>
  )
}

export default TextFontSize
