import AutoScroll from '@/components/AutoScroll'
import HeadingInspector from '@/components/HeadingInspector'
import { lightTheme } from '@/customTheme'
import api from '@/services/api'
import { useStyletron } from 'baseui'
import React, { useEffect, useRef, useState } from 'react'
import WrapImage from './WrapImage'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import { MediaImageRepositoryProcessing } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import { useAppDispatch } from '@/store/store'
import { setSuggestedImagesFromServer } from '@/store/slices/magicBgTool/action'
import { useSelector } from 'react-redux'
import { selectSuggestedImagesFromServer } from '@/store/slices/magicBgTool/selector'
import { Point } from '@/scenes/engine/objects/media-repository/point'

function CategoryDetail({ handleNavigation, categoryActive, handleClickOnSuggetion, caption, image, imageMask, base64ImageWithoutBg, ratioFrame = 1, activeItem, setActiveItem,paddedCrop, imageWithoutBgHtmlElement}) {
  const listCategoryRef = useRef(null)
  const [suggestedImages, setSuggestedImages] = useState([])
  const [totalCategoryDetail, setTotalCategoryDetail] = useState(4)
  const [css] = useStyletron()
  let imageProcessing = new MediaImageRepositoryProcessing()
  const dispatch = useAppDispatch()
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  
  async function getSuggestedResult(prompt: string) {
    api
      .magicBg(image, imageMask, prompt.replace('{object_name}', caption))
      .then(async data => {
        let results = await postProcessMagicBgResult(data);
        setSuggestedImages(pre => [...pre, results] )
        // TODO : put image reuslt on the suggestion options
      })
      .catch(err => {
        // console.log('err', err)
        // setIsLoadedJsonSuccess(true)
      })
  }
  useEffect(() => {
    listCategoryRef.current.scrollTop = listCategoryRef.current.scrollHeight
  }, [totalCategoryDetail])
  let suggestedImagesFromServer = useSelector(selectSuggestedImagesFromServer)

  useEffect(() => {
    if(categoryActive && suggestedImages.length && suggestedImagesFromServer) {
      let data = suggestedImagesFromServer.map(elem => {
        return Object.assign({}, elem);
      });
      if(data.length) {
        if(data.some(category => category.id === categoryActive.id)) {
          let isUpdate = false
          data = data.map(category => {
            if(category.id === categoryActive.id) {
              if(category.value.length < suggestedImages.length) {
                isUpdate = true
                category.value = suggestedImages
              }
            }
            return category
          })
          isUpdate && dispatch(setSuggestedImagesFromServer(data))
        } else {
          dispatch(setSuggestedImagesFromServer([
            ...data,
            {
              id: categoryActive.id,
              value: suggestedImages
            }
          ]))
        }
      } else {
        dispatch(setSuggestedImagesFromServer([
          {
            id: categoryActive.id,
            value: suggestedImages
          }
        ]))
      }
    }

    
  }, [suggestedImages, suggestedImagesFromServer])

  useEffect(() => {
    if (!categoryActive) return
    let data = suggestedImagesFromServer
    if(data && data.length && data.some(category => category.id === categoryActive.id)) {
      setSuggestedImages(data.filter(category => category.id === categoryActive.id)[0].value)
      setTotalCategoryDetail(data.filter(category => category.id === categoryActive.id)[0].value.length)
    } else {
      const abortController = new AbortController()
      const signal = abortController.signal
      for (let i = 0; i < 4; i++) {
        setTimeout(async () => {
          if (!signal.aborted) {
            await api
              .magicBg(image, imageMask, categoryActive.prompt.replace('{object_name}', caption))
              .then(async data => {
                let results = await postProcessMagicBgResult(data);
                setSuggestedImages(pre => [...pre, results] )
              })
              .catch(err => {})
          }
        }, 0)
      }
      return () => {
        abortController.abort()
        setSuggestedImages([])
        setTotalCategoryDetail(4)
      }
    }
  }, [categoryActive])

  async function postProcessMagicBgResult(inputBase64Image: string): Promise<string> {
    let base64Image = `data:image/png;base64,${inputBase64Image}`
    let image = await imageProcessing.loadImage(base64Image);
    let croppedImage = await imageProcessing.cropHtmlImage(image, paddedCrop)
    let compositedImage = await imageProcessing.composite(croppedImage, imageWithoutBgHtmlElement, new Point(0, 0))
    let base64Result = compositedImage.src;
    return base64Result
  }
  return <div
      style={{
        position: 'absolute',
        top: 0,
        height: '100%',
        transition: 'all .3s ease-in-out',
        background: '#fff',
        right: categoryActive ? '0px' : '-356px',
      }}
    >
      <HeadingInspector
        hasBoxShadow={isBoxShadow}
        hasNavigation={true}
        handleNavigation={handleNavigation}
        title={categoryActive.title}
      ></HeadingInspector>
      <AutoScroll
        handleScroll={e => {
          if (e.target.scrollTop > 0) {
            setIsBoxShadow(true)
          } else {
            setIsBoxShadow(false)
          }
        }}
        style={{ display: 'flex', flexDirection: 'column', gap: '50px', height: 'calc(100% - 160px)', paddingBottom: 0 }}
        ref={listCategoryRef}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
            paddingBottom: '16px'
          }}
        >
          {suggestedImages.length
            ? suggestedImages.map(
                (item, index) =>
                  index < totalCategoryDetail && (
                    <div
                      className={css({
                        width: '142px',
                        height: `${142 / ratioFrame}px`,
                        borderRadius: '8px',
                        border: `2px solid ${
                          item === activeItem ? lightTheme.colors.primary : 'transparent'
                        }`,
                        cursor: 'pointer',
                        overflow: 'hidden',
                        objectFit: 'fill',
                      })}
                      onClick={() => {
                        if(item) {
                          setActiveItem(item)
                          handleClickOnSuggetion(item)
                        }
                      }}
                    >
                      <WrapImage srcImage={item} size={142} ratioFrame={ratioFrame} />
                    </div>
                  )
              )
            : null}
          {totalCategoryDetail - suggestedImages.length > 0 ? new Array(totalCategoryDetail - suggestedImages.length).fill(0).map((item, index) => (
            <div
              className={css({
                width: '142px',
                height: `${142 / ratioFrame}px`,
                borderRadius: '8px',
                background: 'rgb(242, 242, 242)',
                cursor: 'not-allowed',
                animation: 'fade 1s infinite',
                overflow: 'hidden',
              })}
            >
              <style>{`
                @keyframes fade {
                  50% {
                    opacity: 0.5;
                  }
                }
              `}</style>
              <WrapImage srcImage={base64ImageWithoutBg} size={142} ratioFrame={ratioFrame} hasHoverState={false} />
            </div>
          )) : null}
          <div style={{width: '100%', paddingTop: '8px'}}>
            <ButtonCustom
              type={SizeButton.LARGE}
              kind={KIND.secondary}
              onClick={() => {
                setTotalCategoryDetail(pre => pre + 4)
                for (let i = 0; i < 4; i++) {
                  getSuggestedResult(categoryActive.prompt)
                }
              }}
              style={{
                background: totalCategoryDetail - suggestedImages.length > 0 ? lightTheme.colors.grayScale50 : 'auto',
                pointerEvents: totalCategoryDetail - suggestedImages.length > 0 ? 'none' : 'auto',
              }}
            >
              <span style={{ ...lightTheme.typography.Small14Semibold, color: totalCategoryDetail - suggestedImages.length > 0 ? '#CCCCCC' : '#666666', }}>Create more</span>
            </ButtonCustom>
          </div>
        </div>
      </AutoScroll>
    </div>
  
}

export default CategoryDetail