import PanelItem from './PanelItem'
import useAppContext from '@/hooks/useAppContext'

function Panels({ isOpen }: { isOpen: boolean }) {
  const { activePanel } = useAppContext()

  return (
    <>
      {/* @ts-ignore */}
      {activePanel !== 'Graphics' && activePanel !== 'Photos' && <PanelItem isOpen={isOpen} />}
    </>
  )
}

export default Panels
