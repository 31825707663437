import { SubMenuType } from '@/constants/editor'
import useAppContext from '@/hooks/useAppContext'
import { styled } from 'baseui'
import PanelItems from './PanelItems'
import { useEditorContext, useRemoveEditorContext } from '@/scenes/engine'
import { PanelType } from '@/constants/app-options'
import Icons from '../Icons'

const Container = styled('div', props => ({
  boxSizing: 'border-box',
  position: 'absolute',
  bottom: '16px',
  top: '16px',
  background: '#ffffff',
  width: '340px',
  // flex: 1,
  // flex: 'none',
  // filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08))',
  boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.08)',
  transition: 'all .4s ease-in',
  zIndex: 100,
  borderRadius: '12px',
}))

function PanelsList() {
  const { activePanel, activeSubMenu, isOpenInspector, setIsOpenInspector, removingBg, isOpenPixelManipulationObject, toolType } =
    useAppContext()

  const { activeObject, editor } = useEditorContext()
  const removeEditor = useRemoveEditorContext().editor
  // useEffect(() => {
  //   setActiveSubMenu(null)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeObject, activePanel])

  const Component =
    (activeObject && activeSubMenu) || (!activeObject && activeSubMenu === SubMenuType.COLOR)
      ? PanelItems[activeSubMenu]
      : activeObject
      ? PanelItems[PanelType.TOOLBOX]
      : PanelItems[activePanel]

  return (
    <>
      <Container
        className="container"
        style={{
          opacity: removingBg && !isOpenPixelManipulationObject ? 0.5 : 1,
          pointerEvents: removingBg ? 'none' : 'auto',
          right: isOpenInspector ? '16px' : '-356px',
        }}
      >
        {Component && <Component />}
      </Container>
      <ToggleInspector
        style={{
          // @ts-ignore
          right: isOpenInspector && activePanel !== 'Photos' && activePanel !== 'Graphics' ? '356px' : 0,
          transition: 'all .4s ease-in',
          opacity: removingBg ? 0.5 : 1,
        }}
        onClick={() => {
          setIsOpenInspector(!isOpenInspector)
          const offset =
            editor.handlers.frameHandler.FRAME_PADDING_INSPECTOR / 2 -
            editor.handlers.frameHandler.FRAME_PADDING_ADD_MENU / 2
          if (editor.handlers.scrollbarHandler && editor.handlers.scrollbarHandler.updateScrollPosition) {
            editor.handlers.scrollbarHandler.updateScrollPosition()
            editor.handlers.frameHandler.updateLayersCoords()
          }
          if (isOpenPixelManipulationObject) {
            removeEditor.handlers.zoomRemoveHandler.isOpenInspector = !isOpenInspector
            const containerElement = toolType === 'Remove' ? document.querySelector(
              '#wrap-canvas-remove-tool .remove-container-class'
            ) as HTMLElement : document.querySelector(
              '#wrap-canvas-remove-tool #wrap-canvas-remove-zoom-tool'
            ) as HTMLElement
            const computedStyle = window.getComputedStyle(containerElement)
            let transformValue = computedStyle.transform
            console.log(transformValue);
            if(!transformValue.includes('matrix')) {
              transformValue = 'matrix(1, 0, 0, 1, 0, 0)'
            }
            
            const matrixValues = transformValue.match(/matrix\((.+)\)/)?.[1].split(', ')
            const translateXAsNumber = matrixValues ? parseFloat(matrixValues[4]) : 0
            const newTranslateX = isOpenInspector ? translateXAsNumber + offset : translateXAsNumber - offset

            // Update the new value of transformValue
            const updatedMatrixValues = matrixValues?.map((value, index) =>
              index === 4 ? newTranslateX.toString() : value
            )
            const updatedTransformValue = `matrix(${updatedMatrixValues?.join(', ')})`

            // Update the containerElement's transform style
            containerElement.style.transform = updatedTransformValue
            const cursorPreview = document.querySelector('.custom-cursor') as HTMLElement
            cursorPreview.style.transform = updatedTransformValue
          }
        }}
      >
        <svg
          width="18"
          height="93"
          viewBox="0 0 18 93"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          filter="drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08))"
        >
          <path
            d="M0 21.6179C0 14.9778 3.46643 8.81952 9.14304 5.37474L18 0V93L9.82717 88.4945C3.76491 85.1525 0 78.7778 0 71.8554V21.6179Z"
            fill="white"
          />
        </svg>

        <div
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          {/* @ts-ignore */}
          {isOpenInspector && activePanel !== 'Photos' && activePanel !== 'Graphics' && (
            <Icons.InspectorArrowClose size={18} />
          )}
          {/* @ts-ignore */}
          {(!isOpenInspector || activePanel === 'Photos' || activePanel === 'Graphics') && (
            <Icons.InspectorArrowOpen size={18} />
          )}
        </div>
      </ToggleInspector>
      <div
        style={{
          width: '22px',
          height: '96px',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          background: '#fff',
          zIndex: 100,
          right: !isOpenInspector ? '-22px' : '334px',
          transition: 'all .4s ease-in',
          opacity: removingBg ? 0 : 1,
          pointerEvents: removingBg ? 'none' : 'auto',
        }}
      ></div>
    </>
  )
}

export default PanelsList

const ToggleInspector = styled('div', props => ({
  position: 'absolute',
  zIndex: 100,
  top: '50%',
  transform: 'translateY(-50%)',
  width: '18px',
  height: '93px',
  ':hover': {
    cursor: 'pointer',
  },
}))