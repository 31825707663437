import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
    transform={props.direction === 'right' ? 'rotate(0)' : props.direction === 'left' ? 'rotate(180)' : props.direction === 'top' ? 'rotate(270)' : props.direction === 'bottom' ? 'rotate(90)' : 'rotate(0)'}
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.28689 17.7048C7.89982 17.3108 7.90539 16.6777 8.29934 16.2906L12.6636 12.0025L8.30129 7.71304C7.90749 7.32582 7.90215 6.69268 8.28937 6.29888C8.67659 5.90508 9.30973 5.89974 9.70354 6.28696L14.6462 11.147C15.1246 11.6174 15.1244 12.3885 14.6459 12.8587L9.70105 17.7172C9.3071 18.1043 8.67396 18.0987 8.28689 17.7048Z" fill={props.fill ? props.fill : '#666666'}/>
  </svg>
)
export default SvgComponent
