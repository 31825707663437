import { ThemeProvider } from 'baseui'
import Group from './components/Group'
import Opacity from './components/Opacity'
import Transform from './components/Transform'
import { lightTheme } from '@/customTheme'
import HeadingInspector from '@/components/HeadingInspector'
import { useEffect, useState } from 'react'
import AutoScroll from '@/components/AutoScroll'
import { useEditorContext } from '@/scenes/engine'
import { ObjectType } from '@/scenes/engine/common/constants'
import { customAmplitude } from '@/utils/customAmplitude'
import { t } from 'i18next'


function MultiElement() {
  const [isBoxShadow, setIsBoxShadow] = useState(false)

  const activeObject = useEditorContext().activeObject

  useEffect(() => {
    const center = activeObject.getCenterPoint()
    activeObject.set({
      originX: 'center',
      originY: 'center',
    })
    activeObject.set({
      left: center.x,
      top: center.y,
    })
    activeObject.setCoords()
    const eventProperties = {
      Tool: 'bazaart.grouping',
    }
    customAmplitude('Selected tool', eventProperties)
  }, [])

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          width: '340px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <HeadingInspector
          hasBoxShadow={isBoxShadow}
          hasNavigation={false}
          title={activeObject.type === ObjectType.GROUP ? t('Group') : t('Multiple elements')}
          hasClose={true}
          handleClose={() => {}}
        ></HeadingInspector>
        <AutoScroll
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
          }}
        >
          <Group />
          <Opacity />
          <Transform />
        </AutoScroll>
      </div>
    </ThemeProvider>
  )
}

export default MultiElement
