import { lightTheme } from '@/customTheme'
import { ThemeProvider, styled } from 'baseui'
import RemoveBgImage from '@assets/images/remove-bg.png'
import RemoveObjectsImage from '@assets/images/remove-objects.jpg'
import MagicBackgroundImage from '@assets/images/magic-background.jpg'
import { useEffect, useRef, useState } from 'react'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useAppDispatch } from '@/store/store'
import Icons from '../Editor/components/Icons'
import { useTranslation } from 'react-i18next'
import RemoveBgIntro from '@assets/video/intro-remove-bg-thumb.mp4'
import RemoveObjectIntro from '@assets/video/intro-remove-thumb.mp4'
import MagicBgIntroThumb from '@assets/video/intro-magic-bg-thumb.mp4'
import VideoIntroMagicBg from '@assets/video/intro-magic-bg.mp4'
import { customAmplitude } from '@/utils/customAmplitude'
import ScrollButton from './components/ScrollButton'

const WrapMagicToolItem = styled('div', props => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  padding: '16px',
  height: '112px',
  minWidth: '398px',
  borderRadius: '16px',
  cursor: 'pointer',
  backgroundColor: lightTheme.colors.white,
  border: `1px solid ${lightTheme.colors.grayScale100}`,
  overflow: 'hidden',
  ':hover': {
    backgroundColor: lightTheme.colors.grayScale50,
  },
  ':active': {
    backgroundColor: lightTheme.colors.grayScale100,
  },
}))

function MagicTool({ handleDropFiles }) {
  const hasPremium = useSelector(selectUserIsPremium)
  const dispatch = useAppDispatch()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [aciton, setAction] = useState<'remove-bg' | 'remove-obj' | 'magic-background' | ''>(null)

  const containerRef = useRef<HTMLDivElement>(null)

  const handleMagicToolClick = (files: FileList) => {
    handleDropFiles(files, aciton)
  }
  const { t } = useTranslation()

  const onClickRemoveBg = () => {
    setAction('remove-bg')
    inputFileRef.current.click()
  }

  const onClickRemoveObj = () => {
    // @ts-ignore
    window.dataLayer.push({ event: 'remove_from_create_page' })
    if (!hasPremium) {
      dispatch(
        setOpenModalTryPremium({
          isOpen: true,
          source: 'BtSubscriptionHeal',
          callback: () => {
            setAction('remove-obj')
            inputFileRef.current.click()
          },
          opened: true,
        })
      )
      const eventProperties = {
        Source: 'BtSubscriptionHeal',
        Type: 'Standard',
      }
      // @ts-ignore
      window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
      customAmplitude('Premium Prompt', eventProperties)
    } else {
      setAction('remove-obj')
      inputFileRef.current.click()
    }
  }
  const onClickMagicBg = () => {
    if (!hasPremium) {
      dispatch(
        setOpenModalTryPremium({
          isOpen: true,
          source: 'BtSubscriptionMagicBackground',
          callback: () => {
            setAction('magic-background')
            inputFileRef.current.click()
          },
          opened: true,
          video: VideoIntroMagicBg,
        })
      )
      const eventProperties = {
        Source: 'BtSubscriptionMagicBackground',
        Type: 'Standard',
      }
      // @ts-ignore
      window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
      customAmplitude('Premium Prompt', eventProperties)
    } else {
      setAction('magic-background')
      inputFileRef.current.click()
    }
  }

  const [visibleScrollBtn, setVisibleScrollBtn] = useState({ left: false, right: false })
  useEffect(() => {
    const handleResize = () => {
      // 1242 is the width of the container
      if(containerRef.current.clientWidth < 1242) {
        setVisibleScrollBtn({ left: false, right: true })
      } else {
        setVisibleScrollBtn({ left: false, right: false })
      }
      if(containerRef.current.scrollLeft === 0) {
        setVisibleScrollBtn(pre => ({ ...pre, left: false }))
      } else {
        setVisibleScrollBtn(pre => ({ ...pre, left: true }))
      }
    };
    handleResize()
    // Add the event listener
    window.addEventListener('resize', handleResize);

    // Remove the event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleScroll = (direction: 'left' | 'right') => {
    const widthMagicItem = 398
    const gap = 24
    let itemsVissible = Math.floor(containerRef.current.clientWidth / (widthMagicItem + gap))
    if(direction === 'left') {
      if(containerRef.current.scrollLeft + containerRef.current.clientWidth === containerRef.current.scrollWidth) {
        containerRef.current.scrollLeft -= containerRef.current.clientWidth
      } else {
      containerRef.current.scrollLeft -= (widthMagicItem + gap) * itemsVissible
      }
      const isReachEnd = containerRef.current.scrollLeft >= containerRef.current.scrollWidth
      setVisibleScrollBtn(pre => ({ left: containerRef.current.scrollLeft < containerRef.current.clientWidth ? false : true, right: isReachEnd ? false : true }))
    } else {
      let newValue = containerRef.current.scrollLeft + (widthMagicItem + gap) * itemsVissible
      containerRef.current.scrollLeft = newValue
      const isReachEnd = newValue + containerRef.current.clientWidth >= containerRef.current.scrollWidth
      setVisibleScrollBtn(pre => ({ left: true, right: isReachEnd ? false : true }))
    }
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', position: 'relative' }}>
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          className="heading"
        >
          <h2 style={{ ...lightTheme.typography.Header20bold }}>{t('Magic tools')}</h2>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '24px', overflow: 'hidden', width: '100%', scrollBehavior: 'smooth' }} ref={containerRef}>
          <MagicToolItem
            srcImage={RemoveBgImage}
            srcVideo={RemoveBgIntro}
            title="Remove background"
            desc="Remove the background from any photo."
            hasPremium={hasPremium}
            hasPremiumIcon={false}
            handleClick={onClickRemoveBg}
          />
          <MagicToolItem
            srcImage={RemoveObjectsImage}
            srcVideo={RemoveObjectIntro}
            title="Remove objects"
            desc="Easily remove people and objects."
            hasPremium={hasPremium}
            hasPremiumIcon={true}
            handleClick={onClickRemoveObj}
          />
          <MagicToolItem
            srcImage={MagicBackgroundImage}
            srcVideo={MagicBgIntroThumb}
            title="Magic Background"
            desc="Create studio-quality photos."
            hasPremium={hasPremium}
            hasPremiumIcon={true}
            handleClick={onClickMagicBg}
          />
        </div>
        {visibleScrollBtn.left ? <ScrollButton direction="left" handle={() => handleScroll('left')} styles={{ left: '0px', top: '84px', transform: 'translateX(-50%)' }} /> : null}
        {visibleScrollBtn.right ? <ScrollButton direction="right" handle={() => handleScroll('right')} styles={{ right: '0px', top: '84px', transform: 'translateX(50%)' }} /> : null}
      </div>
      <input
        onChange={e => handleMagicToolClick(e.target.files)}
        type="file"
        ref={inputFileRef}
        style={{ display: 'none' }}
        accept="image/png, image/jpg, image/jpeg"
      />
    </ThemeProvider>
  )
}

export default MagicTool

function MagicToolItem({ srcImage, srcVideo, title, desc, hasPremium, hasPremiumIcon, handleClick }) {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)

  return (
    <WrapMagicToolItem
      onClick={handleClick}
      onMouseEnter={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
      style={{ gap: isHover ? '8px' : '16px', padding: isHover ? '12px' : '16px', transition: 'all .2s ease-out' }}
    >
      <div style={{ position: 'relative', width: isHover ? '132px' : '120px', height: isHover ? '88px' : '80px', transition: 'all .2s ease-out' }}>
        {isHover ? (
          <video
            src={srcVideo}
            width="100%"
            height="100%"
            style={{
              position: 'absolute',
              inset: 0,
              width: '100%',
              height: '100%',
              zIndex: 2,
              borderRadius: '8px',
            }}
            autoPlay
            muted
            loop
            poster={srcImage}
          >
            <source src={srcVideo} type='video/mp4; codecs=hevc"' />
          </video>
        ) : (
          <></>
        )}
        <img
          src={srcImage}
          alt="magic tool"
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '8px',
            zIndex: 1,
            position: 'absolute',
            inset: 0,
          }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <h3 style={{ ...lightTheme.typography.Header16bold }}>{t(title)}</h3>
        <p style={{ ...lightTheme.typography.Small11medium, color: '#666666' }}>{t(desc)}</p>
      </div>
      <div style={{ position: 'absolute', top: '12px', right: '12px', zIndex: 5 }}>
        {hasPremiumIcon && !hasPremium ? <Icons.PremiumIcon /> : null}
      </div>
    </WrapMagicToolItem>
  )
}
