import Icons from '../../Icons'
import { KIND } from 'baseui/button'
import { useEditorContext, useRemoveEditorContext } from '@/scenes/engine'
import HeadingInspector from '@/components/HeadingInspector'
import AutoScroll from '@/components/AutoScroll'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { LabelLarge } from 'baseui/typography'
import { lightTheme } from '@/customTheme'
import { ThemeProvider } from 'baseui'
import FilterBg from './components/FilterBg'
import BtnOpenNewSlide from '@/components/BtnOpenNewSlide'
import Opacity from './components/Opacity'
import Blending from './components/Blending'
import Transform from './components/Transform'
import { useEffect, useState } from 'react'
import ColorsPackContainer from '@/ColorsPackContainer'
import Fill from '../../Panels/PanelItems/Fill'
import Adjust from './components/Adjust'
import useAppContext from '@/hooks/useAppContext'
import { StaticImage } from 'fabric/fabric-impl'
import api from '@/services/api'
import { dataURLtoBlob, loadImageFromURL } from '@/scenes/engine/utils/image-loader'
import { fabric } from 'fabric'
import { getResizeUrl } from '@/utils/getResizeUrl'
import { ObjectType } from '@/scenes/engine/common/constants'
import Outline from './components/Outline'
import Shadow from './components/Shadow'
import Filter from './components/Filter'
import GridFilter from './components/FilterBg'
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'
import { useSelector } from 'react-redux'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import { customAmplitude } from '@/utils/customAmplitude'
import { useTranslation } from 'react-i18next'
import PixelManipulation from './components/PixelManipulation'

function Sticker() {
  const { setToolType } = useAppContext()
  const removeEditor = useRemoveEditorContext().editor
  const { editor, canvas } = useEditorContext()
  const activeObject = useEditorContext().activeObject as any
  const [isOpenAdjust, setIsOpenAdjust] = useState(false)
  const [isOpenOutline, setIsOpenOutline] = useState(false)
  const [isOpenShadow, setIsOpenShadow] = useState(false)
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState(null)
  const filterPacks = useSelector(selectImageElements)
  const [isOpenFill, setIsOpenFill] = useState(false)
  const [firstOpenFill, setFirstOpenFill] = useState(false)
  const [preObj, setPreObj] = useState(null)
  const [filterActiveId, setFilterActiveId] = useState(null)

  globalThis.startExpanding = false
  globalThis.endAnimation = false

  const expandParticles = () => {
    globalThis.startExpanding = true
  }

  const updateOptions = (object: fabric.IObjectOptions) => {
    // @ts-ignore
    if (object && object.mask) {
      expandParticles()
    }
  }
  useEffect(() => {
    if (!editor) return

    const handleChanges = () => {
      if (activeObject) {
        updateOptions(activeObject)
      } else {
        let object = editor ? editor.handlers.objectsHandler.getDefaultStaticImageObject() : null
        if (object) {
          updateOptions(object)
        }
      }
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  useEffect(() => {
    if (!activeObject) {
      return
    }
    updateOptions(activeObject)
    activeObject.effects = activeObject.effects ? activeObject.effects : {}
    setBackgroundColor(activeObject.effects?.overlay ? activeObject.effects?.overlay.color : null)
    if (preObj && preObj.id !== activeObject.id) {
      setIsOpenFill(false)
      setFirstOpenFill(false)
      setPreObj(activeObject)
    } else {
      setPreObj(activeObject)
    }
  }, [activeObject])

  const updateBackgroundColor = async (color: string, alpha = 1) => {
    if (!color) {
      return
    }
    const preEffects = Object.assign({}, activeObject.effects)
    const preFilters = Object.assign([], activeObject.filters)
    setBackgroundColor(color)
    let newOverlayState = {
      color: color,
      alpha: alpha,
    }
    activeObject.effects.overlay = newOverlayState
    await CanvasImageRenderer.getInstance().render(activeObject, filterPacks)
    canvas.renderAll()
    let sliderOptions: any = { isSliderUpdate: true }
    sliderOptions = {
      isSliderUpdate: true,
      property: ['effects', 'filters'],
      value: [preEffects, preFilters],
    }
    editor.handlers.objectsHandler.updateActive(
      { effects: activeObject.effects, filters: activeObject.filters },
      activeObject,
      sliderOptions
    )
    const eventProperties = {
      Tool: 'bazaart.style.fill',
      Type: 'color',
      'Layer Type': ObjectType.BAZAART_STICKER,
    }
    customAmplitude('Selected tool', eventProperties)
  }

  useEffect(() => {
    setBackgroundColor(activeObject.effects?.overlay ? activeObject.effects?.overlay.color : null)
  }, [activeObject])

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          width: '340px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <HeadingInspector 
          hasBoxShadow={isBoxShadow} 
          hasNavigation={false} 
          title="Sticker"
          hasClose={true} 
          handleClose={() => { canvas.discardActiveObject()}}
        ></HeadingInspector>
        <AutoScroll
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
          }}
        >
          {/* TODO */}
          {/* <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
            <ButtonCustom
              onClick={() => editor.handlers.objectsHandler.toggleCrop()}
              startEnhancer={<Icons.Crop />}
              kind={KIND.tertiary}
              type={SizeButton.LARGE}
            >
              <LabelLarge>Crop</LabelLarge>
            </ButtonCustom>
            <ButtonCustom startEnhancer={<Icons.Erase />} kind={KIND.tertiary} type={SizeButton.LARGE}>
              <LabelLarge>Erase</LabelLarge>
            </ButtonCustom>
          </div> */}
          {/* <ButtonCustom
            startEnhancer={<Icons.Magic />}
            kind={KIND.tertiary}
            type={SizeButton.LARGE}
            style={{ marginTop: '50px' }}
          >
            <LabelLarge>Remove Object</LabelLarge>
          </ButtonCustom> */}

          <ColorsPackContainer
            onItemSelected={updateBackgroundColor}
            colorSelected={backgroundColor}
            style={{marginTop : 0}}
          ></ColorsPackContainer>
          <div style={{gap: '16px' }} className="mt-50">
            <ButtonCustom
              startEnhancer={<Icons.Erase />}
              kind={KIND.tertiary}
              type={SizeButton.LARGE}
              onClick={() => {
                removeEditor.handlers.zoomRemoveHandler.minZoom = editor.handlers.zoomHandler.minZoom
                removeEditor.handlers.zoomRemoveHandler.maxZoom = editor.handlers.zoomHandler.maxZoom
                removeEditor.handlers.zoomRemoveHandler.baseStep = editor.handlers.zoomHandler.baseStep
                removeEditor.handlers.zoomRemoveHandler.sliderStep = editor.handlers.zoomHandler.sliderStep
                setToolType('Eraser')
              }}
            >
              <LabelLarge>{t('Erase')}</LabelLarge>
            </ButtonCustom>
          </div>
          {/* <BtnOpenNewSlide
            startEnhancer={<Icons.FillText size={24} />}
            endEnhancer={<Icons.BackRight size={24} />}
            handleOnClick={() => setIsOpenFill(true)}
            nameAction="Fill"
            style={{
              marginTop: '50px',
            }}
          ></BtnOpenNewSlide>

          <Fill
            isOpen={isOpenFill}
            setIsOpenFill={setIsOpenFill}
            bgActiveID={backgroundIdActive}
            setBgActiveID={setBackgroundIdActive}
            handleSelectBgImage={handleSelectBgImage}
          /> */}
          <div>
            <BtnOpenNewSlide
              handleOnClick={() => setIsOpenOutline(true)}
              startEnhancer={<Icons.Outline />}
              endEnhancer={<Icons.BackRight size={24} />}
              nameAction={t("Outline")}
            ></BtnOpenNewSlide>
            <BtnOpenNewSlide
              handleOnClick={() => setIsOpenShadow(true)}
              startEnhancer={<Icons.Shadow size={24} />}
              endEnhancer={<Icons.BackRight size={24} />}
              nameAction={t("Shadow")}
              style={{
                marginTop: '16px',
              }}
            ></BtnOpenNewSlide>
            <BtnOpenNewSlide
              startEnhancer={<Icons.Adjust size={24} />}
              endEnhancer={<Icons.BackRight size={24} />}
              handleOnClick={() => setIsOpenAdjust(true)}
              nameAction={t("Adjust")}
              style={{
                marginTop: '16px',
              }}
            ></BtnOpenNewSlide>
          </div>
          {/* <FilterBg /> */}
          {(isOpenFill || firstOpenFill) && preObj?.id === activeObject.id ? (
            <Filter isOpen={isOpenFill} setIsOpen={setIsOpenFill} filterObject={activeObject} filterActiveId={filterActiveId} setFilterActiveId={setFilterActiveId} />
          ) : null}
          <GridFilter
            handleSelectItem={() => {}}
            actionToggle={() => {
              let wrapFilterPanel = document.getElementById('wrap-filter-panel')
              if (wrapFilterPanel) {
                wrapFilterPanel.style.display = 'flex'
                wrapFilterPanel.style.right = '0px'
              } else {
                setIsOpenFill(true)
                setFirstOpenFill(true)
              }
            }}
            filterObject={activeObject}
            filterActiveId={filterActiveId} 
            setFilterActiveId={setFilterActiveId}
          />
          <Opacity />
            {/* <Blending /> */}
          <Transform />
        </AutoScroll>
        {/* <div>
        <Button
          onClick={() => setActiveSubMenu(SubMenuType.COLOR)}
          size={SIZE.compact}
          kind={KIND.tertiary}
          shape={SHAPE.square}
        >
          <Icons.FillColor size={24} color="#000000" />
        </Button>
        <Button
          onClick={() => editor.handlers.objectsHandler.toggleCrop()}
          size={SIZE.compact}
          kind={KIND.tertiary}
          shape={SHAPE.square}
        >
          <Icons.FillColor size={24} color="#00ff00" />
        </Button>
        <Animate />
      </div>
      <Common />
      <Adjust />
      <Filter />
      <Shadow/> */}
      </div>
      <Adjust isOpen={isOpenAdjust} setIsOpenAdjust={setIsOpenAdjust} />
      <Outline isOpen={isOpenOutline} setIsOpenOutline={setIsOpenOutline} />
      <Shadow isOpen={isOpenShadow} setIsOpenShadow={setIsOpenShadow} />
      <PixelManipulation />
      {/* {loaded ? (
        <></>
      ) : ( */}

      {/* )} */}
    </ThemeProvider>
  )
}

export default Sticker
