import Icons from '../../../Icons'
import { KIND } from 'baseui/button'
import { useEditorContext } from '@/scenes/engine'
import { useEffect, useState } from 'react'
import { fabric } from 'fabric'
import ColorsPackContainer from '@/ColorsPackContainer'
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'
import AutoScroll from '@/components/AutoScroll'
import HeadingInspector from '@/components/HeadingInspector'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import SliderCustom from '@/components/SliderCustom'
import { LabelLarge } from 'baseui/typography'
import { ThemeProvider } from 'baseui'
import { lightTheme } from '@/customTheme'
import { StaticImageObject } from '@/scenes/engine/objects'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import { useSelector } from 'react-redux'
import { ObjectType } from '@/scenes/engine/common/constants'
import { StaticText } from 'fabric/fabric-impl'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'

function Outline({ isOpen, setIsOpenOutline }) {
  const canvasImageRenderer = CanvasImageRenderer.getInstance()
  const outlineFilter = canvasImageRenderer.outlineFilter
  const [thicknessValue, setThicknessValue] = useState(outlineFilter.minimumForKey('thickness'))
  const [colorValue, setColorValue] = useState(null)
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  const [isInit, setIsInit] = useState(true)
  const [isSecond, setIsSecond] = useState(true)
  const [isReset, setIsReset] = useState(false)
  const [isFirstChange, setIsFirstChange] = useState(true)
  const filterPacks = useSelector(selectImageElements)
  const [isColor, setIsColor] = useState(false)
  const [scaleXBeforeFilter, setScaleXBeforeFilter] = useState(null)
  const [scaleYBeforeFilter, setScaleYBeforeFilter] = useState(null)
  const [filterScalingXBeforeFilter, setFilterScalingXBeforeFilter] = useState(null)
  const [filterScalingYBeforeFilter, setFilterScalingYBeforeFilter] = useState(null)

  const [preEffectsValue, setPreEffectsValue] = useState(null)
  const [preFiltersValue, setPreFiltersValue] = useState([])

  const [preStrokeValue, setPreStrokeValue] = useState(null)
  const [preStrokeWidthValue, setPreStrokeWidthValue] = useState(0)

  const activeObject = useEditorContext().activeObject as unknown as any
  const updatedProperties = ['effects', 'filters', 'scaleX', 'scaleY', '_filterScalingX', '_filterScalingY', 'stroke', 'strokeWidth']

  const editor = useEditorContext().editor
  useEffect(() => {
    updateOptions(activeObject)
    activeObject.on('modified', setScaleBeforeFilter)
    return () => {
      activeObject.off('modified', setScaleBeforeFilter)
    }
  }, [activeObject])

  const setScaleBeforeFilter = (e)=>{
    setScaleXBeforeFilter(activeObject.scaleX)
    setScaleYBeforeFilter(activeObject.scaleY)
  }

  useEffect(() => {
    if (!editor) return
    const handleChanges = (e) => {
      if(e.afterUndo) {
        setIsInit(true)
      }
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])
  
  useEffect(() => {
    setScaleXBeforeFilter(activeObject.scaleX)
    setScaleYBeforeFilter(activeObject.scaleY)
  }, [JSON.stringify(activeObject)])

  const updateOptions = (object: StaticImageObject) => {
    let outlineState = object.effects?.outline
    setScaleXBeforeFilter(object.scaleX)
    setScaleYBeforeFilter(object.scaleY)
    setFilterScalingXBeforeFilter(object._filterScalingX)
    setFilterScalingYBeforeFilter(object._filterScalingY)
    if (outlineState) {
      setThicknessValue(outlineState.thickness)
      setColorValue(outlineState.color)
      setIsFirstChange(false)
    } else {
      reset()
      setIsReset(false)
      if (thicknessValue || colorValue) {
        setIsInit(true)
      }
      setIsSecond(true)
      setIsColor(false)
      setIsFirstChange(true)
    }
    // @ts-ignore
    let assign = Object.assign({}, object.effects)
    setPreEffectsValue(assign)
    //@ts-ignore
    let filters = Object.assign([], object.filters)
    setPreFiltersValue(filters)
  }

  const updateEffect = (finalValue = false) => {
    let sliderOptions: any = { isSliderUpdate: finalValue }
    if (finalValue) {
      const updatedPropertiesValues = [preEffectsValue, preFiltersValue, scaleXBeforeFilter, scaleYBeforeFilter, filterScalingXBeforeFilter, filterScalingYBeforeFilter, preStrokeValue, preStrokeWidthValue]
      //@ts-ignore
      sliderOptions = {
        isSliderUpdate: finalValue,
        property: updatedProperties,
        value: updatedPropertiesValues
      }
      //@ts-ignore
      let assign = Object.assign({}, activeObject.effects)
      setPreEffectsValue(assign)
      //@ts-ignore
      let filters = Object.assign([], activeObject.filters)
      setPreFiltersValue(filters)
      setScaleXBeforeFilter(activeObject.scaleX)
      setScaleYBeforeFilter(activeObject.scaleY)
      setFilterScalingXBeforeFilter(activeObject._filterScalingX)
      setFilterScalingYBeforeFilter(activeObject._filterScalingY)
      setPreStrokeValue(activeObject.stroke)
      setPreStrokeWidthValue(activeObject.strokeWidth)
    }
    //@ts-ignore
    editor.handlers.objectsHandler.updateActive(
      { effects: activeObject.effects, filters: activeObject.filters },
      undefined,
      sliderOptions
    )
  }

  const updateThickness = (value: number) => {
    setThicknessValue(value)
  }

  const updateColor = (color: string) => {
    setColorValue(color)
    setIsColor(true)
  }

  const reset = () => {
    setThicknessValue(outlineFilter.minimumForKey('thickness'))
    setColorValue(null)
    setIsReset(!isFirstChange)
  }

  const renderImage = async () => {
    if (isFirstChange) {
      if (!activeObject.effects.outline) {
        // @ts-ignore
        setThicknessValue(pre => (pre ? pre : outlineFilter.defaultForKey("thickness")))
        setColorValue(pre => (pre ? pre : (activeObject.type === ObjectType.BAZAART_TEXT ? '#ffc900': lightTheme.colors.blackGray)))
      }
      setIsFirstChange(false)
      return
    }
    await applyEffectOutline()
  }

  const applyEffectOutline = async () => {
    let newOutlineState = {
      thickness: thicknessValue,
      color: colorValue,
    }

    activeObject.effects.outline = isReset ? null : newOutlineState
    if (activeObject.type === ObjectType.BAZAART_TEXT) {
      activeObject.stroke = colorValue
      activeObject.strokeWidth = Math.floor(thicknessValue * Math.max(activeObject.width, activeObject.height))
    } else {
      await canvasImageRenderer.render(activeObject, filterPacks)
    }
    editor.handlers.objectsHandler.renderAll()
    if (isColor) {
      updateEffect(true)
      setIsColor(false)
    }
    if (isReset) {
      setIsReset(false)
      updateEffect(true)
      setIsFirstChange(true)
    }
  }

  useEffect(() => {
    if (isInit) {
      setIsInit(false)
      if (activeObject.type === ObjectType.BAZAART_TEXT && !activeObject.effects.outline) {
        activeObject.stroke = null
        activeObject.strokeWidth = 0
        editor.handlers.objectsHandler.renderAll()
      }
    } else {
      ; (async () => {
        if (isSecond) {
          setIsSecond(false)
          //@ts-ignore
          let assign = Object.assign({}, activeObject.effects)
          setPreEffectsValue(assign)
          //@ts-ignore
          let filters = Object.assign([], activeObject.filters)
          setPreFiltersValue(filters)
        }
        await renderImage()
      })()
    }
  }, [thicknessValue, colorValue])

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          width: '340px',
          overflow: 'hidden',
          position: 'absolute',
          top: '0px',
          bottom: '0px',
          right: isOpen ? '0px' : '-356px',
          background: '#fff',
          zIndex: 1,
          transition: 'width .4s ease-in',
          borderRadius: '12px',
        }}
      >
        <HeadingInspector
          hasBoxShadow={isBoxShadow}
          hasNavigation={true}
          handleNavigation={() => setIsOpenOutline(false)}
          title={t("Outline")}
        ></HeadingInspector>
        <AutoScroll
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
          }}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <SliderCustom
            icon={<Icons.Outline size={24} />}
            name={t("Outline")}
            minValue={0}
            maxValue={100}
            listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            onChangeValue={(uiValue, logicValue) => {
              updateThickness(logicValue)
              updateEffect(false)
            }}
            value={thicknessValue}
            logicMaximum={outlineFilter.maximumForKey("thickness")}
            logicMinimum={outlineFilter.minimumForKey("thickness")}
            onFinalChangeValue={(uiValue, logicValue) => {
              const eventProperties = {
                Tool: 'bazaart.style.effects',
                'Layer Type': activeObject.type,
              }
              customAmplitude('Selected tool', eventProperties)
              updateEffect(true)
            }}
            style={{marginTop: 0}}
          />
          <ColorsPackContainer
            style={{
              boxSizing: 'border-box',
              padding: '0',
              width: '300px',
              paddingRight: '8px',
            }}
            onItemSelected={updateColor}
            colorSelected={colorValue}
          ></ColorsPackContainer>
          <div className="mt-50">
            <ButtonCustom
              kind={KIND.tertiary}
              type={SizeButton.LARGE}
              style={{ marginBottom: '50px' }}
              onClick={() => {
                reset()
              }}
            >
              <LabelLarge>{t("Reset")}</LabelLarge>
            </ButtonCustom>
          </div>
        </AutoScroll>
        {/* <Accordion>
        <Panel
          title={
            <Button size={SIZE.default} kind={KIND.tertiary} shape={SHAPE.square}>
              Outline
            </Button>
          }
        >
          <div
            style={{
              background: '#ffffff',
              fontSize: '14px',
            }}
          >
            <div
              style={{
                display: 'grid',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div>
                <div>Width</div>
                <Slider
                  overrides={{
                    InnerThumb: () => null,
                    ThumbValue: () => null,
                    TickBar: () => null,
                    Thumb: {
                      style: {
                        height: '12px',
                        width: '12px',
                      },
                    },
                  }}
                  min={0}
                  max={0.2}
                  step={0.001}
                  marks={false}
                  value={widthValue}
                  onChange={({ value }) => updateWidth(value)}
                />
              </div>
              <div style={{ height: '60px' }}>
                <ColorsPackContainer style={{}} onItemSelected={updateColor}></ColorsPackContainer>
              </div>
              <div></div>
            </div>
          </div>
        </Panel>
      </Accordion> */}
      </div>
    </ThemeProvider>
  )
}

export default Outline
