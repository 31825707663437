import HeadingInspector from '@/components/HeadingInspector'
import AutoScroll from '@/components/AutoScroll'
import { useContext, useEffect, useState } from 'react'
import { EditorContext, RemoveEditorContext } from '@/scenes/engine'
import { useAppDispatch } from '@/store/store'
import SliderCustom from '@/components/SliderCustom'
import Icons from '../../Icons'
import useAppContext from '@/hooks/useAppContext'
import { lightTheme } from '@/customTheme'
import { ObjectType } from '@/scenes/engine/common/constants'
import { EraseBrushObject, EraseType } from '@/scenes/engine/objects'
import EraserObjectHandler from '@/scenes/engine/handlers/remove-handler/EraserObjectHandler'
import { useStyletron } from 'styletron-react'
import { customAmplitude } from '@/utils/customAmplitude'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'fabric/fabric-impl'

const enum BRUSH_TYPE {
  NORMAL,
  SOFT,
  SOFTER,
  HARD,
}

function EraserTool() {
  const dispatch = useAppDispatch()
  const removeEditor = useContext(RemoveEditorContext).editor
  const { canvas } = useContext(RemoveEditorContext)
  const { setisOpenPixelManipulationObject, isOpenPixelManipulationObject, toolType } = useAppContext()
  const [toolActiveOnEarser, setToolActiveOnEarser] = useState('erase')
  const [brushSoftness, setBrushSoftness] = useState(25)
  const [brushOpacity, setBrushOpacity] = useState(100)
  const [isHoverErase, setIsHoverErase] = useState(false)
  const [isHoverRestore, setIsHoverRestore] = useState(false)
  const [brushSize, setBrushSize] = useState(50)
  const { activeObject } = useContext(EditorContext)
  const mainCanvas = useContext(EditorContext).canvas
  const mainEditor = useContext(EditorContext).editor

  const [brushActive, setBrushActive] = useState<BRUSH_TYPE | null>(BRUSH_TYPE.NORMAL)
  useEffect(() => {
    customAmplitude('Selected tool', {
      Tool: 'bazaart.cutout.finger.settings',
    })
  }, [brushActive])

  function doSetBrushSize() {
    removeEditor.handlers.canvasRemoveHandler.originalBrushSize = brushSize
    removeEditor.handlers.canvasRemoveHandler.setBrushSizeOnZoom()
  }

  useEffect(() => {
    removeEditor.handlers.setPixelToolType('Eraser')
    // if(toolType != ''){
    setisOpenPixelManipulationObject(true)
    // }
  }, [])

  useEffect(() => {
    if (!canvas) {
      return
    }
    doSetBrushSize()
  }, [brushSize])

  useEffect(() => {
    if (!canvas) {
      return
    }
    let eraserBrush = canvas.freeDrawingBrush as EraseBrushObject
    eraserBrush.eraseType = toolActiveOnEarser == 'erase' ? EraseType.Erase : EraseType.Restore
  }, [toolActiveOnEarser])

  useEffect(() => {
    // @ts-ignore
    canvas.freeDrawingBrush.softness = brushSoftness
  }, [brushSoftness])

  useEffect(() => {
    // @ts-ignore
    canvas.freeDrawingBrush.opacity = brushOpacity
  }, [brushOpacity])

  const objectIntialized = (object) => {
    // @ts-ignore
    if(object.bazaartGuid == activeObject.bazaartGuid){
      setupTool()
    }
  }

  useEffect(() => {
    if (!isOpenPixelManipulationObject || !removeEditor || toolType !== 'Eraser') {
      return
    }
    let object = activeObject  as unknown as StaticImage
    if (object && object.isIntialized()) {
      setupTool()
    }
    else{
      mainEditor.on('object:Intialized', objectIntialized)
    }
    
    return () => {
      mainEditor.off('object:Intialized', objectIntialized)
    }
  }, [isOpenPixelManipulationObject, toolType])

  const setupTool = () => {
    let eraserObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as EraserObjectHandler

    let canvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')
    ;(async () => {
      setTimeout(async () => {
        const background = await getEraserBackground()
        eraserObjectHandler.background = background
        removeEditor.handlers.pixelManipulationObjectHandler.activeObject = activeObject

        await removeEditor.handlers.pixelManipulationObjectHandler.setupImage()
        await removeEditor.handlers.pixelManipulationObjectHandler.addImageToCanvas()
        // @ts-ignore
        await removeEditor.handlers.pixelManipulationObjectHandler.addEraserBackground(background)
        canvasRemoveTool.style.zIndex = '2'

        eraserObjectHandler.setInitialPosition()

        removeEditor.handlers.canvasRemoveHandler.canvas.freeDrawingBrush.width = brushSize
        let maskScale = Math.max(removeEditor.handlers.pixelManipulationObjectHandler.maskScaleX, removeEditor.handlers.pixelManipulationObjectHandler.maskScaleY)
        // @ts-ignore
        let brush = removeEditor.handlers.canvasRemoveHandler.canvas.freeDrawingBrush as any
        brush.setMaskScale(maskScale)
        doSetBrushSize()

      }, 50)
    })()
  }

  const addCursorPreview = logicValue => {
    removeEditor.handlers.pixelManipulationObjectHandler.addCursorPreview(logicValue)
  }

  const getEraserBackground = async () => {
    let background = ''
    mainCanvas.forEachObject(obj => {
      // @ts-ignore
      if (obj.id === activeObject.id) {
        obj.visible = false
        return
      }
    })
    const frame = mainCanvas.getObjects().find(obj => obj.type === ObjectType.FRAME)
    if (!frame) {
      return background
    }
    const boundingRect = frame.getBoundingRect()
    background = mainCanvas.toDataURL({
      multiplier: 1,
      top: boundingRect.top,
      left: boundingRect.left,
      height: boundingRect.height,
      width: boundingRect.width,
    })
    mainCanvas.forEachObject(obj => {
      // @ts-ignore
      if (obj.id === activeObject.id) {
        obj.visible = true
        return
      }
    })
    return background
  }

  const { t } = useTranslation()

  return (
    <div>
      <HeadingInspector
        hasBoxShadow={false}
        hasNavigation={false}
        handleNavigation={() => {
          setisOpenPixelManipulationObject(false)
        }}
        title={t("Eraser")}
      ></HeadingInspector>
      <AutoScroll style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
        <div
          style={{
            display: 'flex',
            gap: '4px',
            padding: '6px',
            background: lightTheme.colors.grayScale50,
            borderRadius: '26px',
            justifyContent: 'space-around'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              gap: '6px',
              padding: '8px 8px',
              background: toolActiveOnEarser === 'erase' ? '#fff' : 'transparent',
              borderRadius: '20px',
              cursor: 'pointer',
              boxShadow: toolActiveOnEarser === 'erase' ? '0px 0px 4px rgba(0, 0, 0, 0.1)' : 'none',
            }}
            onClick={() => setToolActiveOnEarser('erase')}
          >
            <Icons.Erase fill={toolActiveOnEarser === 'erase' || isHoverErase ? lightTheme.colors.grayScale1000 : lightTheme.colors.grayScale400} />
            <span
              style={{
                ...lightTheme.typography.Small14Semibold,
                color: toolActiveOnEarser === 'erase' || isHoverErase ? lightTheme.colors.grayScale1000 : lightTheme.colors.grayScale400,
              }}
              onMouseEnter={() => {
                setIsHoverErase(toolActiveOnEarser === 'restore')
              }}
              onMouseLeave={() => {
                setIsHoverErase(false)
              }}
            >
              {t('Erase')}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              gap: '6px',
              padding: '8px 8px',
              background: toolActiveOnEarser === 'restore' ? '#fff' : 'transparent',
              borderRadius: '20px',
              cursor: 'pointer',
              boxShadow: toolActiveOnEarser === 'restore' ? '0px 0px 4px rgba(0, 0, 0, 0.1)' : 'none',
            }}
            onClick={() => setToolActiveOnEarser('restore')}
            onMouseEnter={() => {
              setIsHoverRestore(toolActiveOnEarser === 'erase')
            }}
            onMouseLeave={() => {
              setIsHoverRestore(false)
            }}
          >
            <Icons.RestoreIcon fill={toolActiveOnEarser === 'restore' || isHoverRestore ? lightTheme.colors.grayScale1000 : lightTheme.colors.grayScale400} />
            <span
              style={{
                ...lightTheme.typography.Small14Semibold,
                color: toolActiveOnEarser === 'restore' || isHoverRestore ? lightTheme.colors.grayScale1000 : lightTheme.colors.grayScale400,
              }}
            >
              {t('Restore')}
            </span>
          </div>
        </div>
        <SliderCustom
          icon={<Icons.BrushIcon size={24} />}
          name="Brush size"
          minValue={12}
          maxValue={200}
          listValues={[]}
          onChangeValue={(uiValue, logicValue) => {
            addCursorPreview(logicValue)
            setBrushSize(logicValue)
            customAmplitude('Selected tool', {
              Tool: 'bazaart.cutout.finger.settings',
            })
          }}
          value={brushSize}
          style={{ marginTop: 0 }}
        />
        {/* <SliderCustom
                    icon={<Icons.BrushSoftnessIcon size={24} />}
                    name="Brush Softness"
                    minValue={1}
                    maxValue={100}
                    listValues={[
                        12, 20, 30, 40, 50, 60, 70, 80, 90, 100
                    ]}
                    onChangeValue={(uiValue, logicValue) => {
                        setBrushSoftness(logicValue)
                    }}
                    value={brushSoftness}
                    style={{ marginTop: 0 }}
                />

                <SliderCustom
                    icon={<Icons.BrushSoftnessIcon size={24} />}
                    name="Brush opcity"
                    minValue={1}
                    maxValue={100}
                    listValues={[
                        12, 20, 30, 40, 50, 60, 70, 80, 90, 100
                    ]}
                    onChangeValue={(uiValue, logicValue) => {
                        setBrushOpacity(logicValue)
                    }}
                    value={brushOpacity}
                    style={{ marginTop: 0 }}
                /> */}
        <div>
          <div
            style={{
              width: '292px',
              height: '26px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '8px',
            }}
          >
            <span style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title }}>{t('Brush type')}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <BrushButton
              isActive={brushActive === BRUSH_TYPE.NORMAL}
              text={t("Normal")}
              onClick={() => {
                setBrushActive(BRUSH_TYPE.NORMAL)
                setBrushSoftness(25)
                setBrushOpacity(100)
              }}
            >
              <Icons.NormalBrush />
            </BrushButton>
            <BrushButton
              isActive={brushActive === BRUSH_TYPE.SOFT}
              text={t("Soft")}
              onClick={() => {
                setBrushActive(BRUSH_TYPE.SOFT)
                setBrushSoftness(80)
                setBrushOpacity(100)
              }}
            >
              <Icons.SoftBrush />
            </BrushButton>
            <BrushButton
              isActive={brushActive === BRUSH_TYPE.SOFTER}
              text={t("Softer")}
              onClick={() => {
                setBrushActive(BRUSH_TYPE.SOFTER)
                setBrushSoftness(80)
                setBrushOpacity(60)
              }}
            >
              <Icons.SofterBrush />
            </BrushButton>
            <BrushButton
              isActive={brushActive === BRUSH_TYPE.HARD}
              text={t("Hard")}
              onClick={() => {
                setBrushActive(BRUSH_TYPE.HARD)
                setBrushSoftness(0)
                setBrushOpacity(100)
              }}
            >
              <Icons.HardBrush />
            </BrushButton>
          </div>
        </div>
      </AutoScroll>
    </div>
  )
}

export default EraserTool

function BrushButton({ isActive, onClick, children, text }) {
  const [css] = useStyletron()
  const { t } = useTranslation()
  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <div
        className={css({
          boxSizing: 'border-box',
          width: '67px',
          height: '67px',
          background: lightTheme.colors.grayScale0,
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: isActive ? '2px solid #FF0560' : '1px solid #E5E5E5',
          ':hover': {
            background: lightTheme.colors.grayScale50,
            border: isActive ? '2px solid #FF0560' : '1px solid transparent',
          },
          ':active': {
            background: lightTheme.colors.grayScale100,
          },
        })}
      >
        {children}
      </div>
      <p
        style={{
          marginTop: '8px',
          textAlign: 'center',
          fontSize: lightTheme.typography.Small11medium.fontSize,
          fontWeight: lightTheme.typography.Small11medium.fontWeight,
          lineHeight: lightTheme.typography.Small11medium.lineHeight,
          color: lightTheme.colors.grayScale600,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {t(text)}
      </p>
    </div>
  )
}
