import Icons from '../../../Icons'
import { useEditorContext } from '@/scenes/engine'
import { useEffect, useState } from 'react'
import SliderCustom from '@/components/SliderCustom'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'

function Opacity() {
  const [value, setValue] = useState(100)
  const [preOpacity, setPreOpacity] = useState(1)
  const activeObject = useEditorContext().activeObject
  const editor = useEditorContext().editor

  useEffect(() => {
    const handleChanges = () => {
      setValue(Math.round(activeObject.opacity * 100) ?? 100)
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, activeObject])

  // WEBGL POC:
  // const updateOptions = (object: fabric.IObjectOptions) => {
  //   // const updatedValue = [object.opacity * 100]
  //   // @ts-ignore
  //   const updatedValue = [object.filters[0] ? (object.filters[0].brightness + 1) * 100/2 : 50];
  //   console.log("updatedValue" + updatedValue)
  //   setValue(updatedValue)
  // }

  // const updateOpacity = (value: number[]) => {
  //   // debugger;
  //   const opacityValue = value[0] / 100;

  //   // const cactiveObject = <fabric.Image> activeObject;
  //   // const canvas = this.canvas

  //   // const f = fabric.Image.filters;
  //   // this.applyFilter(0,  new f.Grayscale());
  //   // var webglBackend;
  //   // try {
  //   //   webglBackend = new fabric.WebglFilterBackend();
  //   // } catch (e) {
  //   //   console.log(e)
  //   // }
  //   //var canvas2dBackend = new fabric.Canvas2dFilterBackend()
  //   // fabric.initFilterBackend();
  //   // fabric.filterBackend = webglBackend;

  //   // f.Brightness({
  //   //   brightness: parseFloat($('brightness-value').value)
  //   // }));
  //   // var filter = new fabric.Image.filters.Invert();
  //   console.log("got opacityValue" + opacityValue)
  //   // @ts-ignore
  //   var filter = new fabric.Image.filters.Brightness({
  //       brightness: (opacityValue * 2) - 1
  //     });
  //     // @ts-ignore
  //   console.log("will set " + filter.brightness);
  //   // @ts-ignore
  //   activeObject.filters[0] = filter;
  //   // @ts-ignore
  //   activeObject.applyFilters();

  //   editor.handlers.objectsHandler.update({ opacity: 1 })
  //   editor.emit('history:changed', {
  //     hasUndo: true,
  //     hasRedo: true
  //   })
  //   {/* editor.update({ opacity: opacityValue }) */}
  // }

  useEffect(() => {
    setValue(activeObject.opacity * 100 ?? 100)
    setPreOpacity(activeObject.opacity ?? 1)
  }, [activeObject])

  const updateOpacity = (value: number, finalValue = false) => {
    const opacityValue = value / 100
    let sliderOptions: any = { isSliderUpdate: finalValue }
    if (finalValue) {
      sliderOptions = { isSliderUpdate: finalValue, property: ['opacity'], value: [preOpacity] }
      setPreOpacity(opacityValue)
    }
    editor.handlers.objectsHandler.updateActive({ opacity: opacityValue }, null, sliderOptions)
  }
  const { t } = useTranslation()

  return (
    <div style={{ width: '292px'}} className='mt-50'>
      <SliderCustom
        icon={<Icons.Opacity size={24} />}
        name={t('Opacity')}
        minValue={0}
        maxValue={100}
        listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        onChangeValue={value => {
          updateOpacity(value)
          setValue(value)
        }}
        onFinalChangeValue={value => {
          const eventProperties = {
            Tool: 'bazaart.style.opacity',
            'Layer Type': activeObject.type,
          }
          customAmplitude('Selected tool', eventProperties)
          updateOpacity(value, true)
        }}
        value={value}
      />
    </div>
  )
}

export default Opacity
