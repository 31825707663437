import { ObjectType } from '../common/constants'
import { HandlerOptions } from '../common/interfaces'
import BaseHandler from './BaseHandler'
import { fabric } from 'fabric'

type Direction = 'left' | 'right' | 'up' | 'down'
type Corner = 'tl' | 'tr' | 'br' | 'bl' | 'ml' | 'mr'

type VerticalLineCoords = {
  snapX: number
  y1: number
  y2: number
  x1?: number
  x2?: number
  dashOffset: number
}

type HorizontalLineCoords = {
  y1: number
  y2: number
  x1: number
  x2: number
  snapY: number
  dashOffset: number
}

type ACoordsAppendCenter = NonNullable<fabric.Object['aCoords']> & {
  c: fabric.Point
}

const Keys = <T extends object>(obj: T): (keyof T)[] => {
  return Object.keys(obj) as (keyof T)[]
}

class GuidelinesHandler extends BaseHandler {
  public ctx: CanvasRenderingContext2D

  private magnetizationValue = 8
  private magnetizationAngleOffset = 3
  private snapAngles = [0, 90, 180, 270, 360]
  private dashOffsetValue = 6.0
  initialPosition = null
  initialTime = 0
  baseSpeed = 200
  speedLimit
  private adjustedZoomRatio = 1
  direction: Direction
  startingMovement = false
  private verticalLines: VerticalLineCoords[] = []
  private horizontalLines: HorizontalLineCoords[] = []
  private activeObj: fabric.Object | undefined
  private dirty = false
  initialCoords
  baseScreenSizeSnap = 2048
  baseScreenSizeGuideline = 300

  isSnappingX = false
  isSnappingY = false
  currentSnapX = 0
  currentSnapY = 0
  // isScaleSnapping = false
  isScaleSnappingX = false
  isScaleSnappingY = false

  // snappingScaleValue = 0
  snappingScaleXValue = 0
  snappingScaleYValue = 0

  // Text
  snappingFontSize = 0
  snappingTextWidth = 0

  // Mouse
  previousMouseX = 0
  previousMouseY = 0
  snapMouseX = 0
  snapMouseY = 0

  activeObjRatio = 0

  constructor(props: HandlerOptions) {
    super(props)
    this.ctx = this.canvas.getSelectionContext()
    this.initAligningGuidelines(this.canvas)
  }

  initAligningGuidelines(canvas) {
    this._registerEvent()
    this.speedLimit = this.baseSpeed
  }

  // Register Event
  private _registerEvent = () => {
    this.canvas.on('mouse:up', this.onMouseUp)
    this.canvas.on('mouse:down', this.onMouseDown)
    this.canvas.on('object:moving', this.onObjectMoving)
    this.canvas.on('object:rotating', this.onObjectRotating)
    this.canvas.on('object:scaling', this.onObjectScaling)
    this.canvas.on('after:render', this.drawGuideLines.bind(this))
    this.canvas.on('before:render', this.clearGuideline.bind(this))
    this.canvas.on('object:resizing', this.onObjectResizing)
  }

  //----- Start Line handling -----//
  private clearStretLine() {
    this.verticalLines.length = this.horizontalLines.length = 0
  }

  private clearGuideline() {
    if (!this.dirty) return
    this.dirty = false
    // @ts-ignore
    this.canvas.clearContext(this.canvas.getTopContext())
  }

  private consolidateVerticalLines(rotateMovingCoords): void {
    const consolidatedLines: VerticalLineCoords[] = []
    this.verticalLines = this.verticalLines.filter(
      (line, index, self) => self.findIndex(t => t.snapX === line.snapX && t.y1 === line.y1 && t.y2 === line.y2 && t.dashOffset === line.dashOffset) === index
    )
    if (this.snapAngles.includes(this.activeObj.angle)) {
      this.verticalLines.forEach(line => {
        // Find if there is already a line with the same 'x'
        const foundIndex = consolidatedLines.findIndex(consolLine => consolLine.snapX === line.snapX)

        if (foundIndex >= 0) {
          // Update existing line with new min/max values
          consolidatedLines[foundIndex].y1 = Math.min(consolidatedLines[foundIndex].y1, line.y1)
          consolidatedLines[foundIndex].y2 = Math.max(consolidatedLines[foundIndex].y2, line.y2)
        } else {
          // Add new unique line
          consolidatedLines.push({ ...line })
        }
      })
    } else {
      this.verticalLines.forEach(currentLine => {
        let isUnique = true

        // Check if there is an existing line with effectively the same snapY
        for (let filteredLine of consolidatedLines) {
          if (Math.abs(filteredLine.snapX - currentLine.snapX) < 0.0001) {
            isUnique = false
            // Compare distances if they have effectively the same snapY
            const currentDistance = Math.abs(currentLine.x2 - currentLine.x1)
            const filteredDistance = Math.abs(filteredLine.x2 - filteredLine.x1)
            // Replace if current line is longer
            if (currentDistance > filteredDistance) {
              filteredLine.x1 = currentLine.x1
              filteredLine.x2 = currentLine.x2
              filteredLine.y1 = currentLine.y1
              filteredLine.y2 = currentLine.y2
              filteredLine.snapX = currentLine.snapX // Use the snapY of the longer line
              filteredLine.dashOffset = currentLine.dashOffset
            }
            break // No need to check other lines since we found a matching snapY
          }
        }
        if (isUnique) {
          consolidatedLines.push(currentLine)
        }
      })
    }
    this.verticalLines = consolidatedLines
  }

  private consolidateHorizontalLines(movingObjectCoords): void {
    const consolidatedLines: HorizontalLineCoords[] = []
    // Remove duplicate
    this.horizontalLines = this.horizontalLines.filter(
      (line, index, self) => self.findIndex(t => t.snapY === line.snapY && t.x1 === line.x1 && t.x2 === line.x2 && t.dashOffset === line.dashOffset) === index
    )
    if (this.snapAngles.includes(this.activeObj.angle)) {
      // Group by 'x' and calculate min 'y1' and max 'y2' for each group
      this.horizontalLines.forEach(line => {
        // Find if there is already a line with the same 'x'
        const foundIndex = consolidatedLines.findIndex(consolLine => consolLine.snapY === line.snapY)

        if (foundIndex >= 0) {
          // Update existing line with new min/max values
          consolidatedLines[foundIndex].x1 = Math.min(consolidatedLines[foundIndex].x1, line.x1)
          consolidatedLines[foundIndex].x2 = Math.max(consolidatedLines[foundIndex].x2, line.x2)
        } else {
          // Add new unique line
          consolidatedLines.push({ ...line })
        }
      })
    } else {
      this.horizontalLines.forEach(currentLine => {
        let isUnique = true

        // Check if there is an existing line with effectively the same snapY
        for (let filteredLine of consolidatedLines) {
          if (Math.abs(filteredLine.snapY - currentLine.snapY) < 0.0001) {
            isUnique = false
            // Compare distances if they have effectively the same snapY
            const currentDistance = Math.abs(currentLine.y2 - currentLine.y1)
            const filteredDistance = Math.abs(filteredLine.y2 - filteredLine.y1)
            // Replace if current line is longer
            if (currentDistance > filteredDistance) {
              filteredLine.x1 = currentLine.x1
              filteredLine.x2 = currentLine.x2
              filteredLine.y1 = currentLine.y1
              filteredLine.y2 = currentLine.y2
              filteredLine.snapY = currentLine.snapY // Use the snapY of the longer line
              filteredLine.dashOffset = currentLine.dashOffset
            }
            break // No need to check other lines since we found a matching snapY
          }
        }
        if (isUnique) {
          consolidatedLines.push(currentLine)
        }
      })
    }

    this.horizontalLines = consolidatedLines
  }

  drawGuideLines = e => {
    if (!e.ctx || (!this.verticalLines.length && !this.horizontalLines.length) || !this.activeObj) {
      return
    }

    const movingCoords = this.getObjDraggingObjCoords(this.activeObj)
    let rotateMovingCoords: ACoordsAppendCenter = this.snapAngles.includes(this.activeObj.angle) ? this.getVisualAlignedCoords(movingCoords, this.activeObj.angle) : {
      tl: this.rotatePoint(movingCoords.tl, -this.activeObj.angle),
      tr: this.rotatePoint(movingCoords.tr, -this.activeObj.angle),
      br: this.rotatePoint(movingCoords.br, -this.activeObj.angle),
      bl: this.rotatePoint(movingCoords.bl, -this.activeObj.angle),
      c: this.rotatePoint(movingCoords.c, -this.activeObj.angle),
    }
    this.consolidateVerticalLines(rotateMovingCoords)
    this.consolidateHorizontalLines(rotateMovingCoords)

    for (let i = this.verticalLines.length; i--; ) {
      this.drawVerticalLine(this.verticalLines[i], rotateMovingCoords)
    }
    for (let i = this.horizontalLines.length; i--; ) {
      this.drawHorizontalLine(this.horizontalLines[i], rotateMovingCoords)
    }
  }

private drawVerticalLine(coords: VerticalLineCoords, movingCoords: ACoordsAppendCenter) {
    const frame = this.root.frameHandler.get()
    // When frame size is small enough, we do not need to floor the coordinates to make it more accurate, because in the canvas pixel level the object change/move does not much  
    if (!Object.values(movingCoords).some(coord => Math.abs( (Math.min(frame.width, frame.height) < this.baseScreenSizeGuideline ? coord.x : Math.floor(coord.x)) - (Math.min(frame.width, frame.height) < this.baseScreenSizeGuideline ? coords.snapX : Math.floor(coords.snapX)) ) < 0.0001 || Math.abs( (Math.min(frame.width, frame.height) < this.baseScreenSizeGuideline ? coord.x : Math.floor(coord.x)) - (Math.min(frame.width, frame.height) < this.baseScreenSizeGuideline ? coords.snapX : Math.floor(coords.snapX)) ) === 0) ) return
    if (this.activeObj.angle) {
      this.drawLine(coords.x1, coords.y1, coords.x2, coords.y2, coords.dashOffset)
    } else {
      this.drawLine(coords.snapX, Math.min(coords.y1, coords.y2), coords.snapX, Math.max(coords.y1, coords.y2), coords.dashOffset)
    }
  }

  private drawHorizontalLine(coords: HorizontalLineCoords, movingCoords: ACoordsAppendCenter) {
    const frame = this.root.frameHandler.get()
    // When frame size is small enough, we do not need to floor the coordinates to make it more accurate, because in the canvas pixel level the object change/move does not much  
    if (!Object.values(movingCoords).some(coord => Math.abs( (Math.min(frame.width, frame.height) < this.baseScreenSizeGuideline ? coord.y : Math.floor(coord.y)) - (Math.min(frame.width, frame.height) < this.baseScreenSizeGuideline ? coords.snapY : Math.floor(coords.snapY)) ) < 0.0001 || Math.abs( (Math.min(frame.width, frame.height) < this.baseScreenSizeGuideline ? coord.y : Math.floor(coord.y)) - (Math.min(frame.width, frame.height) < this.baseScreenSizeGuideline ? coords.snapY : Math.floor(coords.snapY)) ) === 0) ) return
    if (!Object.values(movingCoords).some(coord => Math.abs(Math.floor(coord.y) - Math.floor(coords.snapY)) < 0.0001)) return
    if (this.activeObj.angle) {
      this.drawLine(coords.x1, coords.y1, coords.x2, coords.y2, coords.dashOffset)
    } else {
      this.drawLine(Math.min(coords.x1, coords.x2), coords.snapY, Math.max(coords.x1, coords.x2), coords.snapY, coords.dashOffset)
    }
  }

  checkPointOnStraightLine(coords: HorizontalLineCoords, point): boolean {
    var gradient = (coords.y2 - coords.y1) / (coords.x2 - coords.x1)
    var intercept = coords.y1 - gradient * coords.x1
    return Math.floor(point.y) - 0.2 <= Math.floor(gradient * point.x + intercept) && Math.floor(gradient * point.x + intercept) <= Math.floor(point.y) + 0.2
  }

  private drawLine(x1: number, y1: number, x2: number, y2: number, dashOffset) {
    // @ts-ignore
    const ctx = this.canvas.getTopContext()
    const point1 = fabric.util.transformPoint(new fabric.Point(x1, y1), this.canvas.viewportTransform)
    const point2 = fabric.util.transformPoint(new fabric.Point(x2, y2), this.canvas.viewportTransform)

    // use origin canvas api to draw guideline
    ctx.save()
    ctx.lineWidth = 1
    ctx.strokeStyle = '#FF0560'
    ctx.setLineDash([dashOffset, dashOffset / 3])
    ctx.beginPath()

    ctx.moveTo(point1.x, point1.y)
    ctx.lineTo(point2.x, point2.y)

    ctx.stroke()
    ctx.restore()
    this.dirty = true
  }
  //----- End Line handling -----//

  private inRange = (a, b, isRotating = false, side: string): boolean => {
    if (isRotating) {
      return Math.abs(a - b) <= this.magnetizationAngleOffset / this.adjustedZoomRatio
    } else {
      const frame = this.root.frameHandler.get()
      const adjustedMagnetizationValue = Math.min(frame.width, frame.height) < this.baseScreenSizeSnap ? this.magnetizationValue * (Math.min(frame.width, frame.height) / this.baseScreenSizeSnap) : this.magnetizationValue
      switch (this.direction) {
        case 'down':
          if (side === 'x') {
            return false
          }
          return a < b && Math.abs(a - b) <= adjustedMagnetizationValue / this.adjustedZoomRatio
        case 'up':
          if (side === 'x') {
            return false
          }
          return a > b && Math.abs(a - b) <= adjustedMagnetizationValue / this.adjustedZoomRatio
        case 'left':
          if (side === 'y') {
            return false
          }
          return a > b && Math.abs(a - b) <= adjustedMagnetizationValue / this.adjustedZoomRatio
        case 'right':
          if (side === 'y') {
            return false
          }
          return a < b && Math.abs(a - b) <= adjustedMagnetizationValue / this.adjustedZoomRatio
        default:
          return Math.abs(a - b) <= adjustedMagnetizationValue / this.adjustedZoomRatio
      }
    }
  }

  private inRangeRotatedObj = (a, b): boolean => {
    const frame = this.root.frameHandler.get()
    const adjustedMagnetizationValue = Math.min(frame.width, frame.height) < this.baseScreenSizeSnap ? this.magnetizationValue * (Math.min(frame.width, frame.height) / this.baseScreenSizeSnap) : this.magnetizationValue
    switch (this.direction) {
      case 'left':
        return a < b && Math.abs(a - b) <= adjustedMagnetizationValue / this.adjustedZoomRatio
      case 'right':
        return a > b && Math.abs(a - b) <= adjustedMagnetizationValue / this.adjustedZoomRatio
      case 'up':
        return a > b && Math.abs(a - b) <= adjustedMagnetizationValue / this.adjustedZoomRatio
      case 'down':
        return a < b && Math.abs(a - b) <= adjustedMagnetizationValue / this.adjustedZoomRatio
      default:
        return Math.abs(a - b) <= adjustedMagnetizationValue / this.adjustedZoomRatio
    }
  }

  private snapObject(obj, side, pos) {
    obj.set(side, pos)
  }

  private calculateDistance(point1, point2) {
    return Math.sqrt((point2.x - point1.x) ** 2 + (point2.y - point1.y) ** 2)
  }

  private getMouseMoveSpeed = (clientX: number, clientY: number): number => {
    const newPosition = { x: clientX, y: clientY }
    const newTime = Date.now()
    const distance = this.calculateDistance(this.initialPosition, newPosition)
    const timeDifference = (newTime - this.initialTime) / 1000
    this.initialPosition = newPosition
    this.initialTime = newTime
    return timeDifference > 0 ? distance / timeDifference : 0
  }

  private getObjectToCompare = (activeObject, direction = '') => {
    let objectToCompare = []
    objectToCompare = this.canvas.getObjects().filter(o => {
      if (
        (Math.floor(o.angle) !== Math.floor(activeObject.angle) && !this.snapAngles.includes(activeObject.angle) && !this.snapAngles.includes(o.angle) ) ||
        o.type === ObjectType.BAZAART_BG ||
        // @ts-ignore
        o.id === activeObject.id ||
        o.type === ObjectType.BAZAART_OVERLAY
      ) {
        return false
      }
      if (o.type === ObjectType.FRAME) {
        return true
      }
      return true
    })
    if (activeObject instanceof fabric.ActiveSelection) {
      objectToCompare = objectToCompare.filter(o => !activeObject.getObjects().includes(o))
    }
    return objectToCompare
  }

  private getObjDraggingObjCoords(activeObject: fabric.Object): ACoordsAppendCenter {
    const coords = this.getCoords(activeObject) //old coords
    const centerPoint = this.calcCenterPointByACoords(coords).subtract(activeObject.getCenterPoint())
    const newCoords = Keys(coords).map(key => coords[key].subtract(centerPoint))
    let tempNewCoord = {
      tl: newCoords[0],
      tr: newCoords[1],
      br: newCoords[2],
      bl: newCoords[3],
      c: activeObject.getCenterPoint(),
    }
    let result = this.getVisualAlignedCoords(tempNewCoord, activeObject.angle)
    return result
  }

  private getVisualAlignedCoords(coords, angle): ACoordsAppendCenter {
    const {tl, tr, bl, br, c} = coords
    switch(angle) {
      case 0: 
        return coords;
      case 90:
        return { tl: bl, tr: tl, br: tr, bl: br, c };
      case 180:
        return { tl: br, tr: bl, br: tl, bl: tr, c };
      case 270:
        return { tl: tr, tr: br, br: bl, bl: tl, c };
    }
  }
  private getObjScalingObjCoords(activeObject: fabric.Object, corner: string): any {
    const coords = this.getCoords(activeObject) //old coords
    const scalingOffset = this.calcCenterPointByACoords(coords).subtract(activeObject.getCenterPoint())
    let newCoords

    switch (corner) {
      case 'br':
        Keys(coords).forEach(key => {
          coords[key].x -= key === 'tr' || key === 'br' ? scalingOffset.x : 0
          coords[key].y -= key === 'bl' || key === 'br' ? scalingOffset.y : 0
        })
        break
      case 'bl':
        Keys(coords).forEach(key => {
          coords[key].x -= key === 'bl' || key === 'tl' ? scalingOffset.x : 0
          coords[key].y -= key === 'bl' || key === 'br' ? scalingOffset.y : 0
        })
        break
      case 'tr':
        Keys(coords).forEach(key => {
          coords[key].x -= key === 'tr' || key === 'br' ? scalingOffset.x : 0
          coords[key].y -= key === 'tr' || key === 'tl' ? scalingOffset.y : 0
        })
        break
      case 'tl':
        Keys(coords).forEach(key => {
          coords[key].x -= key === 'tl' || key === 'bl' ? scalingOffset.x : 0
          coords[key].y -= key === 'tl' || key === 'tr' ? scalingOffset.y : 0
        })
        break
      case 'mr':
        Keys(coords).forEach(key => {
          coords[key].x -= key === 'tr' || key === 'br' ? scalingOffset.x : 0
        })
        break
      case 'ml':
        Keys(coords).forEach(key => {
          coords[key].x -= key === 'tl' || key === 'bl' ? scalingOffset.x : 0
        })
        break
    }
    newCoords = { ...coords, c: activeObject.getCenterPoint() }
    return newCoords
  }

  isValidSpeed = (e): boolean => {
    const speed = this.getMouseMoveSpeed(e.clientX, e.clientY)
    return speed <= this.speedLimit
  }

  filterSnapValue = offsetArray => {
    let minOffset = Math.min(...offsetArray.map(item => item.offset))
    return offsetArray.filter(item => item.offset === minOffset)
  }

  getMouseMoveDirection = (event): Direction => {
    let direction: Direction
    if (event.clientX > this.previousMouseX) {
      direction = 'right'
    } else if (event.clientY > this.previousMouseY) {
      direction = 'down'
    } else if (event.clientY < this.previousMouseY) {
      direction = 'up'
    } else if (event.clientX < this.previousMouseX) {
      direction = 'left'
    }
    this.previousMouseX = event.clientX
    this.previousMouseY = event.clientY
    return direction
  }

  private getCoords(obj: fabric.Object) {
    const [tl, tr, br, bl] = obj.getCoords(true)
    return { tl, tr, br, bl }
  }

  private calcCenterPointByACoords(coords: NonNullable<fabric.Object['aCoords']>): fabric.Point {
    return new fabric.Point((coords.tl.x + coords.br.x) / 2, (coords.tl.y + coords.br.y) / 2)
  }

  // Logic
  private handleSnapping = event => {
    if (this.startingMovement) {
      this.startingMovement = false
      return
    }
    this.direction = this.getMouseMoveDirection(event.e)
    // @ts-ignore
    const target = event.target
    this.activeObj = target
    if (!target) {
      return
    }
    if (!this.isValidSpeed(event.e)) {
      if (this.isSnappingY) {
      }

      if (this.isSnappingX) {
      }
      return
    }
    this.getPossibleSnapping(event)
  }

  rotatePoint(point, angleDegrees) {
    const angleRadians = (angleDegrees * Math.PI) / 180
    const x = point.x * Math.cos(angleRadians) - point.y * Math.sin(angleRadians)
    const y = point.x * Math.sin(angleRadians) + point.y * Math.cos(angleRadians)
    return new fabric.Point(x, y)
  }

  private omitCoords(objCoords: ACoordsAppendCenter, type: 'vertical' | 'horizontal') {
    const newCoords = objCoords
    const axis = type === 'vertical' ? 'x' : 'y'
    Keys(objCoords).forEach(key => {
      if (objCoords[key][axis] < newCoords.tl[axis]) {
        newCoords[key] = objCoords[key]
      }
      if (objCoords[key][axis] > newCoords.tl[axis]) {
        newCoords[key] = objCoords[key]
      }
    })
    return newCoords
  }

  getPossibleSnapping = event => {
    const target = event.target
    const objectsToCompare = this.getObjectToCompare(target, this.direction)
    const objCoordsByMovingDistance = this.getObjDraggingObjCoords(target)
    const snapXPoints: Set<number> = new Set()
    const snapYPoints: Set<number> = new Set()

    if (!this.isValidSpeed(event.e)) {
      if (this.direction === 'up' || this.direction === 'down') {
        if (this.isSnappingY) {
          target.setPositionByOrigin(new fabric.Point(objCoordsByMovingDistance.c.x, this.currentSnapY + (this.direction === 'up' ? -1 : 1)), 'center', 'center')
          this.isSnappingY = false
        }
      }

      if (this.direction === 'left' || this.direction === 'right') {
        if (this.isSnappingX) {
          target.setPositionByOrigin(new fabric.Point(this.currentSnapX + (this.direction === 'left' ? -1 : 1), objCoordsByMovingDistance.c.y), 'center', 'center')
          this.isSnappingX = false
        }
      }
      return
    }
    objectsToCompare.forEach(o => {
      const baseOCoords = {
        ...this.getCoords(o),
        c: o.getCenterPoint(),
      }
      const oCoords = this.snapAngles.includes(o.angle) ? this.getVisualAlignedCoords(baseOCoords, o.angle) : baseOCoords
      const oRotateCoord = this.snapAngles.includes(o.angle) ? oCoords : {
        tl: this.rotatePoint(oCoords.tl, -o.angle),
        tr: this.rotatePoint(oCoords.tr, -o.angle),
        br: this.rotatePoint(oCoords.br, -o.angle),
        bl: this.rotatePoint(oCoords.bl, -o.angle),
        c: this.rotatePoint(oCoords.c, -o.angle),
      }
      let movingObjRotateCoord = this.snapAngles.includes(target.angle) ? objCoordsByMovingDistance :
        {
          tl: this.rotatePoint(objCoordsByMovingDistance.tl, -target.angle),
          tr: this.rotatePoint(objCoordsByMovingDistance.tr, -target.angle),
          br: this.rotatePoint(objCoordsByMovingDistance.br, -target.angle),
          bl: this.rotatePoint(objCoordsByMovingDistance.bl, -target.angle),
          c: this.rotatePoint(objCoordsByMovingDistance.c, -target.angle),
        }
      Keys(objCoordsByMovingDistance).forEach(activeObjPoint => {
        const newVerticalCoords = o.angle !== 0 ? this.omitCoords(oCoords, 'vertical') : oCoords
        const newHorizontalCoords = o.angle !== 0 ? this.omitCoords(oCoords, 'horizontal') : oCoords

        const calcVerticalLineCoords = (objPoint, activeObjCoords, offset) => {
          let y1: number, y2: number, x1: number, x2: number
          if (objPoint === 'c') {
            y1 = Math.min((oCoords.tl.y + oCoords.tr.y) / 2, activeObjPoint === 'c' ? (activeObjCoords.tl.y + activeObjCoords.tr.y) / 2 : activeObjCoords[activeObjPoint].y)
            y2 = Math.max((oCoords.bl.y + oCoords.br.y) / 2, activeObjPoint === 'c' ? (activeObjCoords.bl.y + activeObjCoords.br.y) / 2 : activeObjCoords[activeObjPoint].y)
            x1 =
              y1 === (oCoords.tl.y + oCoords.tr.y) / 2
                ? (oCoords.tl.x + oCoords.tr.x) / 2
                : (activeObjPoint === 'c' ? (activeObjCoords.tl.x + activeObjCoords.tr.x) / 2 : activeObjCoords[activeObjPoint].x) - offset
            x2 =
              y2 === (oCoords.bl.y + oCoords.br.y) / 2
                ? (oCoords.bl.x + oCoords.br.x) / 2
                : (activeObjPoint === 'c' ? (activeObjCoords.bl.x + activeObjCoords.br.x) / 2 : activeObjCoords[activeObjPoint].x) - offset
          } else {
            y1 = Math.min(oCoords[objPoint].y, activeObjPoint === 'c' ? (activeObjCoords.tl.y + activeObjCoords.tr.y) / 2 : activeObjCoords[activeObjPoint].y)
            y2 = Math.max(oCoords[objPoint].y, activeObjPoint === 'c' ? (activeObjCoords.bl.y + activeObjCoords.br.y) / 2 : activeObjCoords[activeObjPoint].y)
            x1 = y1 === oCoords[objPoint].y ? oCoords[objPoint].x : (activeObjPoint === 'c' ? (activeObjCoords.tl.x + activeObjCoords.tr.x) / 2 : activeObjCoords[activeObjPoint].x) - offset
            x2 = y2 === oCoords[objPoint].y ? oCoords[objPoint].x : (activeObjPoint === 'c' ? (activeObjCoords.bl.x + activeObjCoords.br.x) / 2 : activeObjCoords[activeObjPoint].x) - offset
          }
          return { x1, x2, y1, y2 }
        }
        const calcHorizontalLineCoords = (objPoint, activeObjCoords, offset) => {
          let x1: number, x2: number, y1: number, y2: number
          if (objPoint === 'c') {
            x1 = Math.min((oCoords.tl.x + oCoords.bl.x) / 2, activeObjPoint === 'c' ? (activeObjCoords.tl.x + activeObjCoords.bl.x) / 2 : activeObjCoords[activeObjPoint].x)
            x2 = Math.max((oCoords.tr.x + oCoords.br.x) / 2, activeObjPoint === 'c' ? (activeObjCoords.tr.x + activeObjCoords.br.x) / 2 : activeObjCoords[activeObjPoint].x)
            y1 =
              x1 === (oCoords.tl.x + oCoords.bl.x) / 2
                ? (oCoords.tl.y + oCoords.bl.y) / 2
                : (activeObjPoint === 'c' ? (activeObjCoords.tl.y + activeObjCoords.bl.y) / 2 : activeObjCoords[activeObjPoint].y) - offset
            y2 =
              x2 === (oCoords.tr.x + oCoords.br.x) / 2
                ? (oCoords.tr.y + oCoords.br.y) / 2
                : (activeObjPoint === 'c' ? (activeObjCoords.tr.y + activeObjCoords.br.y) / 2 : activeObjCoords[activeObjPoint].y) - offset
          } else {
            x1 = Math.min(oCoords[objPoint].x, activeObjPoint === 'c' ? (activeObjCoords.tl.x + activeObjCoords.bl.x) / 2 : activeObjCoords[activeObjPoint].x)
            x2 = Math.max(oCoords[objPoint].x, activeObjPoint === 'c' ? (activeObjCoords.tr.x + activeObjCoords.br.x) / 2 : activeObjCoords[activeObjPoint].x)
            y1 = x1 === oCoords[objPoint].x ? oCoords[objPoint].y : (activeObjPoint === 'c' ? (activeObjCoords.tl.y + activeObjCoords.bl.y) / 2 : activeObjCoords[activeObjPoint].y) - offset
            y2 = x2 === oCoords[objPoint].x ? oCoords[objPoint].y : (activeObjPoint === 'c' ? (activeObjCoords.tr.y + activeObjCoords.br.y) / 2 : activeObjCoords[activeObjPoint].y) - offset
          }
          return { x1, x2, y1, y2 }
        }
        // Snap x
        Keys(newVerticalCoords).forEach(objPoint => {
          if (!this.isSnappingX) {
            if (
              !this.snapAngles.includes(target.angle)
                ? this.inRangeRotatedObj(movingObjRotateCoord[activeObjPoint].x, oRotateCoord[objPoint].x)
                : this.inRange(movingObjRotateCoord[activeObjPoint].x, oRotateCoord[objPoint].x, false, 'x')
            ) {
              const x = oRotateCoord[objPoint].x
              const offset = movingObjRotateCoord[activeObjPoint].x - x
              const snapPoint = objCoordsByMovingDistance.c.x - offset
              snapXPoints.add(snapPoint)
              const { x1, x2, y1, y2 } = calcVerticalLineCoords(
                objPoint,
                {
                  ...objCoordsByMovingDistance,
                  c: this.calcCenterPointByACoords(objCoordsByMovingDistance),
                },
                offset
              )
              this.verticalLines.push({ snapX: x, x1, x2, y1, y2, dashOffset: o.type === ObjectType.FRAME ? 0 : this.dashOffsetValue })
            }
          } else {
            if (Math.abs(this.snapMouseX - event.e.clientX) > 10) {
              this.isSnappingX = false
              this.snapMouseX = 0
              snapXPoints.clear()
            } else {
              target.setPositionByOrigin(
                new fabric.Point(this.currentSnapX === 0 ? objCoordsByMovingDistance.c.x : this.currentSnapX, this.isSnappingY ? this.currentSnapY : objCoordsByMovingDistance.c.y),
                'center',
                'center'
              )
            }
          }
        })
        // Snap y
        Keys(newHorizontalCoords).forEach(objPoint => {
          if (!this.isSnappingY) {
            if (
              !this.snapAngles.includes(target.angle)
                ? this.inRangeRotatedObj(movingObjRotateCoord[activeObjPoint].y, oRotateCoord[objPoint].y)
                : this.inRange(movingObjRotateCoord[activeObjPoint].y, oRotateCoord[objPoint].y, false, 'y')
            ) {
              const y = oRotateCoord[objPoint].y
              const offset = movingObjRotateCoord[activeObjPoint].y - y
              snapYPoints.add(objCoordsByMovingDistance.c.y - offset)

              let { x1, x2, y1, y2 } = calcHorizontalLineCoords(
                objPoint,
                {
                  ...objCoordsByMovingDistance,
                  c: this.calcCenterPointByACoords(objCoordsByMovingDistance),
                },
                offset
              )
              this.horizontalLines.push({ snapY: y, y1, y2, x1, x2, dashOffset: o.type === ObjectType.FRAME ? 0 : this.dashOffsetValue })
            }
          } else {
            if (Math.abs(this.snapMouseY - event.e.clientY) > 10) {
              this.isSnappingY = false
              this.snapMouseY = 0
              snapYPoints.clear()
            } else {
              target.setPositionByOrigin(
                new fabric.Point(this.isSnappingX ? this.currentSnapX : objCoordsByMovingDistance.c.x, this.currentSnapY === 0 ? objCoordsByMovingDistance.c.y : this.currentSnapY),
                'center',
                'center'
              )
            }
          }
        })
      })
    })
    if (!this.isSnappingX) {
      this.snapX(event, objCoordsByMovingDistance, snapXPoints, snapYPoints)
    }
    if (!this.isSnappingY) {
      this.snapY(event, objCoordsByMovingDistance, snapXPoints, snapYPoints)
    }
  }

  snapX(event, draggingObjCoords, snapXPoints: Set<number>, snapYPoints: Set<number>) {
    const activeObject = event.target
    if (snapXPoints.size === 0) {
      this.isSnappingX = false
      this.snapMouseX = 0
      return
    }
    this.speedLimit = this.baseSpeed * 3
    this.snapMouseX = event.e.clientX
    this.isSnappingX = true
    const sortPoints = (list: Set<number>, originPoint: number): number => {
      if (list.size === 0) {
        return originPoint
      }
      const sortedList = [...list].sort((a, b) => Math.abs(originPoint - a) - Math.abs(originPoint - b))
      return sortedList[0]
    }
    this.currentSnapX = sortPoints(snapXPoints, draggingObjCoords.c.x)
    this.currentSnapY = this.isSnappingY ? this.currentSnapY : sortPoints(snapYPoints, draggingObjCoords.c.y)
    activeObject.setPositionByOrigin(new fabric.Point(this.currentSnapX, this.currentSnapY), 'center', 'center')
  }

  snapY(event, draggingObjCoords, snapXPoints: Set<number>, snapYPoints: Set<number>) {
    const activeObject = event.target
    if (snapYPoints.size === 0) {
      this.isSnappingY = false
      this.snapMouseY = 0
      return
    }
    this.speedLimit = this.baseSpeed * 3
    this.snapMouseY = event.e.clientY
    this.isSnappingY = true
    const sortPoints = (list: Set<number>, originPoint: number): number => {
      if (list.size === 0) {
        return originPoint
      }
      const sortedList = [...list].sort((a, b) => Math.abs(originPoint - a) - Math.abs(originPoint - b))
      return sortedList[0]
    }
    this.currentSnapX = this.isSnappingX ? this.currentSnapX : sortPoints(snapXPoints, draggingObjCoords.c.x)
    this.currentSnapY = sortPoints(snapYPoints, draggingObjCoords.c.y)
    activeObject.setPositionByOrigin(new fabric.Point(this.currentSnapX, this.currentSnapY), 'center', 'center')
  }
  /**
   * Event handler actions
   */
  resetAll = () => {
    this.startingMovement = false
    // @ts-ignore
    this.canvas.clearContext(this.canvas.contextTop)
    this.initialPosition = null
    this.initialTime = 0
    this.previousMouseX = this.previousMouseY = 0
    this.snappingScaleXValue = this.snappingScaleYValue = 0
    this.isSnappingX = this.isSnappingY = false
    this.isScaleSnappingX = this.isScaleSnappingY = false
    this.snapMouseX = this.snapMouseY = 0

    if (this.horizontalLines.length || this.verticalLines.length) {
      this.clearGuideline()
      this.clearStretLine()
    }
  }

  // Event handler
  onMouseUp = () => {
    this.resetAll()
  }

  onMouseDown = event => {
    const target = event.target
    if (target) {
      this.initialCoords = target.getCoords(true)
      this.activeObjRatio = target.width / target.height
    }
    this.startingMovement = true
    this.initialPosition = { x: event.e.clientX, y: event.e.clientY }
    this.initialTime = Date.now()
    this.adjustedZoomRatio = this.canvas.getZoom() !== this.root.frameHandler.getFitRatio() ? this.canvas.getZoom() / this.root.frameHandler.getFitRatio() : 1
    this.previousMouseX = event.e.clientX
    this.previousMouseY = event.e.clientY
  }

  onObjectMoving = event => {
    this.handleSnapping(event)
  }

  onObjectRotating = event => {
    if (this.startingMovement) {
      this.startingMovement = false
      return
    }
    this.snapAngles = [0, 90, 180, 270, 360]
    this.canvas.getObjects().forEach(obj => {
      // @ts-ignore
      if (obj.id === event.target.id) return
      !this.snapAngles.includes(Math.round(obj.angle)) && this.snapAngles.push(Math.round(obj.angle))
    })
    const object = event.target
    this.snapAngles.forEach(val => {
      if (this.inRange(object.angle, val, true, '')) {
        this.snapObject(object, 'angle', val === 360 ? 0 : val)
      }
    })
  }

  onObjectResizing = event => {
    const target = event.target
    if (!target) {
      return
    }
    if (target.type === ObjectType.STATIC_TEXT || target.type === ObjectType.BAZAART_TEXT) {
      this.onObjectScaling(event)
    }
  }

  onObjectScaling = event => {
    if (this.startingMovement) {
      this.startingMovement = false
      return
    }
    this.direction = this.getMouseMoveDirection(event.e)
    const target = event.target
    if (!target) {
      return
    }
    this.activeObj = target
    let snapPoint: fabric.Point
    let originX: string
    let originY: string
    const corner: Corner = event.transform.corner
    // TODO: temporatily hide
    if(corner === 'ml' || corner === 'mr') {
      return
    }
    let newScaleX = 0
    let newScaleY = 0
    // let newScale = 0
    const objectsToCompare = this.getObjectToCompare(target)
    const objCoordsByMovingDistance = this.getObjScalingObjCoords(target, corner)

    switch (corner) {
      case 'tl':
        snapPoint = objCoordsByMovingDistance.br
        originX = 'right'
        originY = 'bottom'
        break
      case 'bl':
        snapPoint = objCoordsByMovingDistance.tr
        originX = 'right'
        originY = 'top'
        break
      case 'tr':
        snapPoint = objCoordsByMovingDistance.bl
        originX = 'left'
        originY = 'bottom'
        break
      case 'br':
        snapPoint = objCoordsByMovingDistance.tl
        originX = 'left'
        originY = 'top'
        break
      // case 'ml':
      // snapPoint = {...objCoordsByMovingDistance.tr, y: (objCoordsByMovingDistance.tr.y + objCoordsByMovingDistance.br.y) / 2}
      // originX = 'right'
      // originY = 'center'
      // break
      // case 'mr':
      // snapPoint = objCoordsByMovingDistance.tl
      // originX = 'left'
      // originY = 'center'
      // break
    }

    if (!this.isValidSpeed(event.e)) {
      /**
       * If the speed is too high, we will not snap the object
       * Check if speed is too high and scale is greater and less than snap point, if it is less than snap point release the snap and decrease/increase scale base on direction 
       */
      let adjustScaleX = 0,
        adjustScaleY = 0
      let isIncreasing
      switch (corner) {
        case 'tl':
          isIncreasing = this.direction === 'up' || this.direction === 'left'
          if (this.isScaleSnappingX && this.snappingScaleXValue !== 0) {
            const conditionX = isIncreasing ? target.scaleX > this.snappingScaleXValue : target.scaleX <= this.snappingScaleXValue
            adjustScaleX = conditionX ? target.scaleX : this.snappingScaleXValue + (isIncreasing ? 0.05 : -0.05)
          }
          if (this.isScaleSnappingY && this.snappingScaleYValue !== 0) {
            const conditionY = isIncreasing ? target.scaleY > this.snappingScaleYValue : target.scaleY < this.snappingScaleYValue
            adjustScaleY = conditionY ? target.scaleY : this.snappingScaleYValue + (isIncreasing ? 0.05 : -0.05)
          }
          break
        case 'bl':
          isIncreasing = this.direction === 'down' || this.direction === 'left'
          if (this.isScaleSnappingX && this.snappingScaleXValue !== 0) {
            const conditionX = isIncreasing ? target.scaleX > this.snappingScaleXValue : target.scaleX < this.snappingScaleXValue
            adjustScaleX = conditionX ? target.scaleX : this.snappingScaleXValue + (isIncreasing ? 0.05 : -0.05)
          }
          if (this.isScaleSnappingY && this.snappingScaleYValue !== 0) {
            const conditionY = isIncreasing ? target.scaleY > this.snappingScaleYValue : target.scaleY < this.snappingScaleYValue
            adjustScaleY = conditionY ? target.scaleY : this.snappingScaleYValue + (isIncreasing ? 0.05 : -0.05)
          }

          break
        case 'tr':
          isIncreasing = this.direction === 'up' || this.direction === 'right'
          if (this.isScaleSnappingX && this.snappingScaleXValue !== 0) {
            const conditionX = isIncreasing ? target.scaleX > this.snappingScaleXValue : target.scaleX < this.snappingScaleXValue
            adjustScaleX = conditionX ? target.scaleX : this.snappingScaleXValue + (isIncreasing ? 0.05 : -0.05)
          }
          if (this.isScaleSnappingY && this.snappingScaleYValue !== 0) {
            const conditionY = isIncreasing ? target.scaleY > this.snappingScaleYValue : target.scaleY < this.snappingScaleYValue
            adjustScaleY = conditionY ? target.scaleY : this.snappingScaleYValue + (isIncreasing ? 0.05 : -0.05)
          }
       
          break
        case 'br':
          isIncreasing = this.direction === 'down' || this.direction === 'right'
          if (this.isScaleSnappingX && this.snappingScaleXValue !== 0) {
            const conditionX = isIncreasing ? target.scaleX > this.snappingScaleXValue : target.scaleX < this.snappingScaleXValue
            adjustScaleX = conditionX ? target.scaleX : this.snappingScaleXValue + (isIncreasing ? 0.05 : -0.05)
          }
          if (this.isScaleSnappingY && this.snappingScaleYValue !== 0) {
            const conditionY = isIncreasing ? target.scaleY > this.snappingScaleYValue : target.scaleY < this.snappingScaleYValue
            adjustScaleY = conditionY ? target.scaleY : this.snappingScaleYValue + (isIncreasing ? 0.05 : -0.05)
          }
          break
      }
      if (adjustScaleX !== 0) {
        target.set({
          scaleX: adjustScaleX,
        })
        target.setPositionByOrigin(snapPoint, originX, originY)
      }
      if (adjustScaleY !== 0) {
        target.set({
          scaleY: adjustScaleY,
        })
        target.setPositionByOrigin(snapPoint, originX, originY)
      }
    }

    objectsToCompare.forEach(o => {
      const oCoords = {
        ...this.getCoords(o),
        c: o.getCenterPoint(),
      }
      const oRotateCoord = {
        tl: this.rotatePoint(oCoords.tl, -o.angle),
        tr: this.rotatePoint(oCoords.tr, -o.angle),
        br: this.rotatePoint(oCoords.br, -o.angle),
        bl: this.rotatePoint(oCoords.bl, -o.angle),
        c: this.rotatePoint(oCoords.c, -o.angle),
      }
      const scalingObjRotateCoord = {
        tl: this.rotatePoint(objCoordsByMovingDistance.tl, -target.angle),
        tr: this.rotatePoint(objCoordsByMovingDistance.tr, -target.angle),
        br: this.rotatePoint(objCoordsByMovingDistance.br, -target.angle),
        bl: this.rotatePoint(objCoordsByMovingDistance.bl, -target.angle),
        c: this.rotatePoint(objCoordsByMovingDistance.c, -target.angle),
      }
      Keys(objCoordsByMovingDistance).forEach(activeObjPoint => {
        const newVerticalCoords = o.angle !== 0 ? this.omitCoords(oCoords, 'vertical') : oCoords
        const newHorizontalCoords = o.angle !== 0 ? this.omitCoords(oCoords, 'horizontal') : oCoords

        const calcVerticalLineCoords = (objPoint, activeObjCoords, offset) => {
          let y1: number, y2: number, x1: number, x2: number
          if (objPoint === 'c') {
            y1 = Math.min((oCoords.tl.y + oCoords.tr.y) / 2, activeObjPoint === 'c' ? (activeObjCoords.tl.y + activeObjCoords.tr.y) / 2 : activeObjCoords[activeObjPoint].y)
            y2 = Math.max((oCoords.bl.y + oCoords.br.y) / 2, activeObjPoint === 'c' ? (activeObjCoords.bl.y + activeObjCoords.br.y) / 2 : activeObjCoords[activeObjPoint].y)
            x1 =
              y1 === (oCoords.tl.y + oCoords.tr.y) / 2
                ? (oCoords.tl.x + oCoords.tr.x) / 2
                : activeObjPoint === 'c'
                ? (activeObjCoords.tl.x + activeObjCoords.tr.x) / 2
                : activeObjCoords[activeObjPoint].x
            x2 =
              y2 === (oCoords.bl.y + oCoords.br.y) / 2
                ? (oCoords.bl.x + oCoords.br.x) / 2
                : activeObjPoint === 'c'
                ? (activeObjCoords.bl.x + activeObjCoords.br.x) / 2
                : activeObjCoords[activeObjPoint].x
          } else {
            y1 = Math.min(oCoords[objPoint].y, activeObjPoint === 'c' ? (activeObjCoords.tl.y + activeObjCoords.tr.y) / 2 : activeObjCoords[activeObjPoint].y)
            y2 = Math.max(oCoords[objPoint].y, activeObjPoint === 'c' ? (activeObjCoords.bl.y + activeObjCoords.br.y) / 2 : activeObjCoords[activeObjPoint].y)
            x1 = y1 === oCoords[objPoint].y ? oCoords[objPoint].x : activeObjPoint === 'c' ? (activeObjCoords.tl.x + activeObjCoords.tr.x) / 2 : activeObjCoords[activeObjPoint].x + offset
            x2 = y2 === oCoords[objPoint].y ? oCoords[objPoint].x : activeObjPoint === 'c' ? (activeObjCoords.bl.x + activeObjCoords.br.x) / 2 : activeObjCoords[activeObjPoint].x + offset
          }
          return { x1, x2, y1, y2 }
        }
        const calcHorizontalLineCoords = (objPoint, activeObjCoords, offset) => {
          let x1: number, x2: number, y1: number, y2: number
          if (objPoint === 'c') {
            x1 = Math.min((oCoords.tl.x + oCoords.bl.x) / 2, activeObjPoint === 'c' ? (activeObjCoords.tl.x + activeObjCoords.bl.x) / 2 : activeObjCoords[activeObjPoint].x)
            x2 = Math.max((oCoords.tr.x + oCoords.br.x) / 2, activeObjPoint === 'c' ? (activeObjCoords.tr.x + activeObjCoords.br.x) / 2 : activeObjCoords[activeObjPoint].x)
            y1 =
              x1 === (oCoords.tl.x + oCoords.bl.x) / 2
                ? (oCoords.tl.y + oCoords.bl.y) / 2
                : (activeObjPoint === 'c' ? (activeObjCoords.tl.y + activeObjCoords.bl.y) / 2 : activeObjCoords[activeObjPoint].y) + offset
            y2 =
              x2 === (oCoords.tr.x + oCoords.br.x) / 2
                ? (oCoords.tr.y + oCoords.br.y) / 2
                : (activeObjPoint === 'c' ? (activeObjCoords.tr.y + activeObjCoords.br.y) / 2 : activeObjCoords[activeObjPoint].y) + offset
          } else {
            x1 = Math.min(oCoords[objPoint].x, activeObjPoint === 'c' ? (activeObjCoords.tl.x + activeObjCoords.bl.x) / 2 : activeObjCoords[activeObjPoint].x)
            x2 = Math.max(oCoords[objPoint].x, activeObjPoint === 'c' ? (activeObjCoords.tr.x + activeObjCoords.br.x) / 2 : activeObjCoords[activeObjPoint].x)
            y1 = x1 === oCoords[objPoint].x ? oCoords[objPoint].y : (activeObjPoint === 'c' ? (activeObjCoords.tl.y + activeObjCoords.bl.y) / 2 : activeObjCoords[activeObjPoint].y) + offset
            y2 = x2 === oCoords[objPoint].x ? oCoords[objPoint].y : (activeObjPoint === 'c' ? (activeObjCoords.tr.y + activeObjCoords.br.y) / 2 : activeObjCoords[activeObjPoint].y) + offset
          }
          return { x1, x2, y1, y2 }
        }
        // Snap X
        Keys(newVerticalCoords).forEach(objPoint => {
          switch (corner) {
            case 'tl':
            case 'bl':
              // case 'ml':
              if (activeObjPoint === 'tr' || activeObjPoint === 'br') {
                return
              }
              break
            case 'tr':
            case 'br':
              // case 'mr':
              if (activeObjPoint === 'tl' || activeObjPoint === 'bl') {
                return
              }
              break
          }

          if (!this.isScaleSnappingX) {
            if (this.inRange(scalingObjRotateCoord[activeObjPoint].x, oRotateCoord[objPoint].x, false, 'x')) {
              const x = oRotateCoord[objPoint].x
              //calculate new scale
              let expectedWidth
              switch (corner) {
                case 'tl':
                  expectedWidth = Math.abs(x - scalingObjRotateCoord.br.x)
                  break
                case 'bl':
                  expectedWidth = Math.abs(x - scalingObjRotateCoord.tr.x)
                  break
                case 'tr':
                  expectedWidth = Math.abs(x - scalingObjRotateCoord.bl.x)
                  break
                case 'br':
                  expectedWidth = Math.abs(x - scalingObjRotateCoord.tl.x)
                  break
                // case 'ml':
                //   expectedWidth = Math.abs(x - scalingObjRotateCoord.tr.x)
                //   break
                // case 'mr':
                //   expectedWidth = Math.abs(x - scalingObjRotateCoord.tl.x)
                //   break
              }
              if (expectedWidth === 0) {
                return
              }

              if (activeObjPoint === 'c') {
                expectedWidth *= 2
              }
              // newScale = expectedWidth / target.width
              newScaleX = expectedWidth / target.width
              const offset = expectedWidth - target.getScaledWidth()
              const { y1, y2 } = calcVerticalLineCoords(
                objPoint,
                {
                  ...objCoordsByMovingDistance,
                  c: this.calcCenterPointByACoords(objCoordsByMovingDistance),
                },
                offset
              )
              this.verticalLines.push({ snapX: x, y1, y2, dashOffset: o.type === ObjectType.FRAME ? 0 : this.dashOffsetValue })
            }
          } else {
            if (Math.abs(this.snapMouseX - event.e.clientX) > 10 || Math.abs(this.snapMouseY - event.e.clientY) > 10) {
              this.isScaleSnappingX = false
              this.snapMouseX = 0
            } else {
              if (target.type === ObjectType.BAZAART_TEXT || target.type === ObjectType.STATIC_TEXT) {
                target.set({
                  fontSize: this.snappingFontSize,
                  width: this.snappingTextWidth,
                  scaleX: 1,
                  scaleY: 1,
                })
              } else {
                target.set({
                  scaleX: this.snappingScaleXValue,
                  scaleY: this.snappingScaleYValue,
                })
              }
              target.setPositionByOrigin(snapPoint, originX, originY)
            }
          }
        })

        // Snap Y
        Keys(newHorizontalCoords).forEach(objPoint => {
          // if (corner === 'ml' || corner === 'mr') {
          //   return
          // }
          switch (corner) {
            case 'tl':
            case 'tr':
              if (activeObjPoint === 'bl' || activeObjPoint === 'br') {
                return
              }
              break
            case 'bl':
            case 'br':
              if (activeObjPoint === 'tl' || activeObjPoint === 'tr') {
                return
              }
              break
          }
          if (!this.isScaleSnappingY) {
            if (this.inRange(scalingObjRotateCoord[activeObjPoint].y, oRotateCoord[objPoint].y, false, 'y')) {
              const y = oRotateCoord[objPoint].y
              //calculate new scale
              let expectedHeight
              switch (corner) {
                case 'tl':
                  expectedHeight = Math.abs(y - scalingObjRotateCoord.bl.y)
                  break
                case 'bl':
                  expectedHeight = Math.abs(y - scalingObjRotateCoord.tr.y)
                  break
                case 'tr':
                  expectedHeight = Math.abs(y - scalingObjRotateCoord.bl.y)
                  break
                case 'br':
                  expectedHeight = Math.abs(y - scalingObjRotateCoord.tl.y)
                  break
              }
              if (expectedHeight === 0) {
                return
              }
              if (activeObjPoint === 'c') {
                expectedHeight *= 2
              }
              newScaleY = expectedHeight / target.height

              const offset = (expectedHeight - target.getScaledHeight()) * (corner === 'tl' || corner === 'tr' ? -1 : 1)
              // Calculate line coords
              let { x1, x2, y1, y2 } = calcHorizontalLineCoords(
                objPoint,
                {
                  ...objCoordsByMovingDistance,
                  c: this.calcCenterPointByACoords(objCoordsByMovingDistance),
                },
                offset
              )
              this.horizontalLines.push({ snapY: y, y1: y1, y2: y2, x1, x2, dashOffset: o.type === ObjectType.FRAME ? 0 : this.dashOffsetValue })
            }
          } else {
            if (Math.abs(this.snapMouseY - event.e.clientY) > 10 || Math.abs(this.snapMouseX - event.e.clientX) > 10){
              this.isScaleSnappingY = false
              this.snapMouseY = 0
              this.snappingFontSize = 0
              this.snappingTextWidth = 0
            } else {
              if (target.type === ObjectType.BAZAART_TEXT || target.type === ObjectType.STATIC_TEXT) {
                target.set({
                  fontSize: this.snappingFontSize,
                  width: this.snappingTextWidth,
                  scaleX: 1,
                  scaleY: 1,
                })
                target.setPositionByOrigin(snapPoint, originX, originY)
              } else {
                target.set({
                  scaleX: this.snappingScaleXValue,
                  scaleY: this.snappingScaleYValue,
                })
                target.setPositionByOrigin(snapPoint, originX, originY)
              }
            }
          }
        })
      })
    })
    if (target.type === ObjectType.BAZAART_TEXT || target.type === ObjectType.STATIC_TEXT) {
      let fontSize, scaleX, width
      if (!this.isScaleSnappingX && !this.isScaleSnappingY) {
        if (newScaleX > 0 && newScaleY > 0) {
          this.snapMouseX = event.e.clientX
          this.snapMouseY = event.e.clientY
          this.isScaleSnappingX = true
          this.isScaleSnappingY = true

          fontSize = target.width * newScaleX
          width = target.width * newScaleX

          this.snappingFontSize = fontSize
          this.snappingTextWidth = width

          target.set({
            fontSize,
            width,
            scaleX: 1,
            scaleY: 1,
          })
          target.setPositionByOrigin(snapPoint, originX, originY)
          return
        }
        if (newScaleX > 0) {
          fontSize = target.fontSize * newScaleX
          width = target.width * newScaleX
          this.snappingFontSize = fontSize
          this.snappingTextWidth = width
          this.isScaleSnappingX = true
          this.snapMouseX = event.e.clientX
          this.snapMouseY = event.e.clientY
          target.set({
            fontSize,
            width,
            scaleX: 1,
            scaleY: 1,
          })
          target.setPositionByOrigin(snapPoint, originX, originY)

          return
        }
        if (newScaleY > 0) {
          const newHeight = target.height * newScaleY
          width = newHeight * this.activeObjRatio
          scaleX = width / target.width
          fontSize = target.fontSize * scaleX
          this.snappingFontSize = fontSize
          this.snappingTextWidth = width
          this.isScaleSnappingY = true
          this.snapMouseY = event.e.clientY

          target.set({
            fontSize,
            width,
            scaleX: 1,
            scaleY: 1,
          })
          target.setPositionByOrigin(snapPoint, originX, originY)

          return
        }
      }
      if (!this.isScaleSnappingX) {
        if (newScaleX > 0) {
          this.isScaleSnappingX = true
          this.snapMouseX = event.e.clientX
          this.snapMouseY = event.e.clientY
          this.speedLimit = this.baseSpeed * 3
          fontSize = target.fontSize * newScaleX
          width = target.width * newScaleX
          if ((this.snappingTextWidth === 0 && this.snappingFontSize === 0) || (Math.abs(fontSize - this.snappingFontSize) > 0.0001 && Math.abs(width - this.snappingTextWidth) > 0.0001)) {
            this.snappingTextWidth = width
            this.snappingFontSize = fontSize
          }
          target.set({
            fontSize: this.snappingFontSize,
            width: this.snappingTextWidth,
            scaleX: 1,
            scaleY: 1,
          })
          target.setPositionByOrigin(snapPoint, originX, originY)
        } else {
          this.speedLimit = this.baseSpeed
          this.isScaleSnappingX = false
        }
      }

      if (!this.isScaleSnappingY) {
        if (newScaleY > 0) {
          const newHeight = target.height * newScaleY
          width = newHeight * this.activeObjRatio
          scaleX = width / target.width
          fontSize = target.fontSize * scaleX

          this.snappingFontSize = fontSize
          this.snappingTextWidth = width
          this.isScaleSnappingY = true

          this.snapMouseY = event.e.clientY
          this.snapMouseX = event.e.clientX

          if ((this.snappingTextWidth === 0 && this.snappingFontSize === 0) || (Math.abs(fontSize - this.snappingFontSize) > 0.0001 && Math.abs(width - this.snappingTextWidth) > 0.0001)) {
            this.snappingTextWidth = width
            this.snappingFontSize = fontSize
          }
          target.set({
            fontSize: this.snappingFontSize,
            width: this.snappingTextWidth,
            scaleX: 1,
            scaleY: 1,
          })
          target.setPositionByOrigin(snapPoint, originX, originY)
        } else {
          this.speedLimit = this.baseSpeed
          this.isScaleSnappingY = false
        }
      }
    }
    // Non-Text object
    else {
      if (!this.isScaleSnappingX && !this.isScaleSnappingY) {
        if (newScaleX > 0 && newScaleY > 0) {
          this.snapMouseX = event.e.clientX
          this.snapMouseY = event.e.clientY
          this.isScaleSnappingX = true
          this.isScaleSnappingY = true
          this.snappingScaleXValue = newScaleX
          this.snappingScaleXValue = newScaleY
          target.set({
            scaleX: newScaleX,
            scaleY: newScaleY,
          })
          target.setPositionByOrigin(snapPoint, originX, originY)
          return
        }
        if (newScaleX > 0) {
          this.snapMouseX = event.e.clientX
          this.snapMouseY = event.e.clientY
          this.isScaleSnappingX = true
          const newWidth = target.width * newScaleX
          const newHeight = newWidth / this.activeObjRatio
          this.snappingScaleXValue = newScaleX
          this.snappingScaleYValue = newHeight / target.height
          target.set({
            scaleX: newScaleX,
            scaleY: this.snappingScaleYValue,
          })
          target.setPositionByOrigin(snapPoint, originX, originY)
          return
        }

        if (newScaleY > 0) {
          this.isScaleSnappingY = true
          this.snapMouseX = event.e.clientX
          this.snapMouseY = event.e.clientY

          const newHeight = target.height * newScaleY
          const newWidth = newHeight * this.activeObjRatio

          this.snappingScaleXValue = newWidth / target.width
          this.snappingScaleYValue = newScaleY
          target.set({
            scaleX: this.snappingScaleXValue,
            scaleY: newScaleY,
          })
          target.setPositionByOrigin(snapPoint, originX, originY)
          return
        }
      }

      if (!this.isScaleSnappingX) {
        if (newScaleX > 0 && event.transform.original.scaleX !== newScaleX) {
          this.isScaleSnappingX = true
          this.snapMouseX = event.e.clientX
          this.snapMouseY = event.e.clientY
          this.speedLimit = this.baseSpeed * 3
          if (this.snappingScaleXValue === 0) {
            this.snappingScaleXValue = newScaleX
            target.set({
              scaleX: newScaleX,
            })
          } else if (Math.abs(this.snappingScaleXValue - newScaleX) > 0.0001) {
            target.set({
              scaleX: this.snappingScaleXValue,
            })
          } else {
            this.snappingScaleXValue = newScaleX
            target.set({
              scaleX: newScaleX,
            })
          }
          target.setPositionByOrigin(snapPoint, originX, originY)
        } else {
          this.speedLimit = this.baseSpeed
          this.isScaleSnappingX = false
        }
      }

      if (!this.isScaleSnappingY) {
        if (newScaleY > 0 && event.transform.original.scaleY !== newScaleY) {
          this.isScaleSnappingY = true
          this.snapMouseX = event.e.clientX
          this.snapMouseY = event.e.clientY
          this.speedLimit = this.baseSpeed * 3
          if (this.snappingScaleYValue === 0) {
            this.snappingScaleYValue = newScaleY
            target.set({
              scaleY: newScaleY,
            })
          } else if (Math.abs(this.snappingScaleYValue - newScaleY) > 0.0001) {
            target.set({
              scaleY: this.snappingScaleYValue,
            })
          } else {
            this.snappingScaleYValue = newScaleY
            target.set({
              scaleY: newScaleY,
            })
          }
          target.setPositionByOrigin(snapPoint, originX, originY)
        } else {
          this.speedLimit = this.baseSpeed
          this.isScaleSnappingY = false
        }
      }
    }
  }
}

export default GuidelinesHandler
