import { Button, KIND, SHAPE, SIZE } from 'baseui/button'
import { styled } from 'baseui'
import { Plus, CheckIndeterminate } from 'baseui/icon'
import { useEditorContext } from '@/scenes/engine'
import { Slider } from 'baseui/slider'
import { ThemeProvider } from 'baseui'
import { lightTheme } from '@/customTheme'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { useStyletron } from 'baseui'
import useAppContext from '@/hooks/useAppContext'
import { LabelLarge } from 'baseui/typography'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectZoomRatio } from '@/store/slices/editor/selector'
import { customAmplitude } from '@/utils/customAmplitude'
import { useTranslation } from 'react-i18next'
const Container = styled('div', props => ({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  position: 'absolute',
  bottom: '16px',
  zIndex: 1,
}))

function Footer() {
  const [css, theme] = useStyletron()
  const { removingBg, isOpenInspector, isOpenPixelManipulationObject } = useAppContext()
  const zoomRatio = useSelector(selectZoomRatio)

  const editor = useEditorContext().editor
  const [openResetZoom, setOpenResetZoom] = useState(false)

  useEffect(() => {
    if (zoomRatio && editor && zoomRatio !== 1) {
      if (
        Math.floor(editor.handlers.frameHandler.getFitRatio() * 100) >= Math.floor(zoomRatio * 100) - 2.5 &&
        Math.floor(editor.handlers.frameHandler.getFitRatio() * 100) <= Math.floor(zoomRatio * 100) + 2.5
      ) {
        setOpenResetZoom(false)
      } else {
        setOpenResetZoom(true)
      }
    }
  }, [editor && editor.handlers.frameHandler.getFitRatio(), zoomRatio])

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={lightTheme}>
      <Container
        $style={{
          left: isOpenInspector ? '88px' : 0,
          right: isOpenInspector ? '356px' : 0,
          transition: 'all .4s ease-in',
          zIndex: isOpenPixelManipulationObject ? -1 : 1,
        }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            position: 'relative',
            background: '#fff',
            height: '40px',
            display: 'flex',
            gap: '8px',
            borderRadius: '8px',
            padding: '4px',
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.08)',
            opacity: removingBg ? 0.5 : 1,
          }}
        >
          <ButtonCustom
            kind={KIND.minimal}
            type={SizeButton.SMALL}
            shape={SHAPE.square}
            onClick={() => {
              zoomRatio >= 0.1 && editor.handlers.zoomHandler.zoomOut()
            }}
            tooltip={{
              content: 'Zoom out',
              placement: 'top',
            }}
          >
            <CheckIndeterminate color="#666666" size={24} title="" />
          </ButtonCustom>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'center',
              width: '118px',
            })}
          >
            <Slider
              value={[zoomRatio * 100 > 300 ? 300 : zoomRatio * 100]}
              min={10}
              max={300}
              step={2.9}
              onChange={({ value }) => {
                let ratio = value[0] / 100
                return value && editor.handlers.zoomHandler.zoomToRatio(ratio)
              }}
              onFinalChange={({ value }) => {
                const eventProperties = {
                  Tool: 'bazaart.zoom',
                }
                customAmplitude('Selected tool', eventProperties)
              }}
              overrides={{
                Track: {
                  style: ({ $theme }) => ({
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }),
                },
                InnerThumb: ({ $value, $thumbIndex }) => <>{}</>,
                ThumbValue: () => null,
                Thumb: {
                  style: () => ({
                    height: '20px',
                    width: '20px',
                    borderRadius: '50%',
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: '#fff',
                  }),
                },
                InnerTrack: {
                  style: ({ $theme }) => {
                    return {
                      // @ts-ignore
                      background: `linear-gradient(to right, ${$theme.colors.grayScale600} 0%, ${
                        // @ts-ignore
                        $theme.colors.grayScale600
                        // @ts-ignore
                      } ${((zoomRatio - 0.1) / 2.9) * 100}%, ${$theme.colors.grayScale100} ${
                        ((zoomRatio - 0.1) / 2.9) * 100
                      }%, ${
                        // @ts-ignore
                        $theme.colors.grayScale100
                      } 100%)`,
                      width: '100%',
                    }
                  },
                },
                Tick: {
                  style: ({ $theme }) => ({
                    display: 'none',
                  }),
                },
                TickBar: {
                  style: ({ $theme }) => ({
                    display: 'none',
                  }),
                },
              }}
            />
          </div>
          <ButtonCustom
            kind={KIND.minimal}
            type={SizeButton.SMALL}
            shape={SHAPE.square}
            onClick={() => {
              zoomRatio <= 30 && editor.handlers.zoomHandler.zoomIn()
            }}
            tooltip={{
              content: 'Zoom in',
              placement: 'top',
            }}
          >
            <Plus color="#666666" size={24} title="" />
          </ButtonCustom>

          <ButtonCustom
            kind={KIND.minimal}
            type={SizeButton.STANDARD}
            onClick={() => {
              editor.handlers.zoomHandler.zoomToFit()
              editor.handlers.scrollbarHandler.hideScrollbar()
              setOpenResetZoom(false)
            }}
            style={{
              position: 'absolute',
              left: 'calc(100% + 8px)',
              top: 0,
              bottom: 0,
              background: '#fff',
              boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.08)',
              padding: '8px 12px',
              width: 'max-content',
              minWidth: '106px',
              letterSpacing: '-0.006em',
              opacity: openResetZoom ? 1 : 0,
              transition: 'opacity .4s linear',
              ':hover': {
                background: '#F9F9F9'
              },
              ':active': {
                background: '#F2F2F2'
              }
            }}
          >
            <LabelLarge>{t('Reset Zoom')}</LabelLarge>
          </ButtonCustom>
        </div>
      </Container>
    </ThemeProvider>
  )
}

export default Footer
