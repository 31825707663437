import { FC } from 'react'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import { PersistGate } from 'redux-persist/integration/react'
import { LightTheme, BaseProvider } from 'baseui'
import { AppProvider } from './contexts/AppContext'
import store, { persistor } from '@store/store'
import { Provider } from 'react-redux'
import { EditorProvider } from './scenes/engine/context/editor'
import { RemoveEditorProvider } from './scenes/engine'
import useStyletronHotjarSync from './utils/useStyletronHotjarSync'

const engine = new Styletron()

const Providers: FC = ({ children }) => {
  useStyletronHotjarSync()

  return (
    <Provider store={store}>
      <PersistGate loading={<div>HEllo</div>} persistor={persistor}>
        <StyletronProvider value={engine}>
          <EditorProvider>
            <RemoveEditorProvider>
              <BaseProvider theme={LightTheme}>
                <AppProvider>{children}</AppProvider>
              </BaseProvider>
            </RemoveEditorProvider>
          </EditorProvider>
        </StyletronProvider>
      </PersistGate>
    </Provider>
  )
}

export default Providers
