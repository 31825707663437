import { useEffect, useRef, useState } from 'react'
import useAppContext from '@/hooks/useAppContext'
import { useParams } from 'react-router'
import { getElements } from '@store/slices/elements/actions'
import { getFonts } from '@store/slices/fonts/actions'
import { getBackgroundPacks } from '@store/slices/backgroundPacks/actions'
import { useAppDispatch } from '@store/store'
import Navbar from './components/Navbar'
import Panels from './components/Panels'
import Toolbox from './components/Toolbox'
import Footer from './components/Footer'
import api from '@services/api'
import Editor from '../engine/BZCanvas'
import { useEditorContext } from '../engine'
import { getGraphicPacks } from '@/store/slices/graphicsPacks/actions'
import ExportImage from './components/Navbar/ExportImage'
import Loading from '@/components/Loading'
import DropZone from '@/components/Dropzone'
// import { useEditor } from '../engine/hooks/useEditor'
import { uniqueFilename } from '@/utils/unique'
import { setUploading, uploadFile } from '@/store/slices/uploads/actions'
import { selectUploading, selectUploads } from '@/store/slices/uploads/selectors'
import { useSelector } from 'react-redux'
import { useStyletron } from 'baseui'
import { loadImageFromURL } from '../engine/utils/image-loader'
import { ObjectType } from '../engine/common/constants'
import { Button, KIND, SHAPE, SIZE } from 'baseui/button'
import { Link } from 'react-router-dom'
import emptyFramBG from '@assets/images/empty_frame_bg.svg'
import Resizer from 'react-image-file-resizer'

function Magic({ location }) {
  const { setCurrentTemplate } = useAppContext()
  // console.log(useEditorContext())
  const [currentFile, setCurrentFile] = useState<any>(null)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const uploads = useSelector(selectUploads)
  const uploading = useSelector(selectUploading)
  const editor = useEditorContext().editor
  const dispatch = useAppDispatch()
  const [loaded, setLoaded] = useState(false)
  const [filePath, setFilePath] = useState('')
  // const { activeObject } = useEditorContext()
  const activeObject = useEditorContext().activeObject
  const [css, theme] = useStyletron()

  globalThis.startExpanding = false

  useEffect(() => {
    if (!editor) return

    const handleChanges = () => {
      if (activeObject) {
        updateOptions(activeObject)
      } else {
        let object = editor ? editor.handlers.objectsHandler.getDefaultStaticImageObject() : null
        if (object) {
          updateOptions(object)
        }
      }
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  const updateOptions = (object: fabric.IObjectOptions) => {
    // @ts-ignore
    if (object && object.mask) {
      // setLoaded(true);
      expandParticles()
    }
  }

  useEffect(() => {
    updateOptions(activeObject)
  }, [activeObject])

  useEffect(() => {
    if (uploads.length > 0) {
      editor.handlers.zoomHandler.zoomToFit()
      addImageToCanvas(uploads[0].url)
    }
  }, [uploads])

  // useEffect(() => {
  //   if (activeObject) {
  //     new Promise(r => setTimeout(r, 1500)).then(function () {
  //       // on Magic we want transperent bg
  //       // editor.handlers.frameHandler.update({visible:false})
  //       editor.handlers.objectsHandler.update({selectable:false})
  //       expandParticles()
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeObject])

  const editorConfig = {
    clipToFrame: true,
    visible: false,
  }

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })

  const handleDropFiles = async (files: FileList) => {
    drawParticles()
    const file = files[0]
    const image_file = (await resizeFile(file)) as File
    handleUploadFile(image_file)
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      function () {
        setCurrentFile(reader.result)
      },
      false
    )

    if (image_file) {
      reader.readAsDataURL(image_file)
    }
  }

  const handleUploadFile = async (file: File) => {
    try {
      const updatedFileName = uniqueFilename(file.name)
      const updatedFile = new File([file], updatedFileName)
      dispatch(
        setUploading({
          progress: 0,
          status: 'IN_PROGRESS',
        })
      )
      dispatch(uploadFile({ file: updatedFile }))
      // const response = await api.getSignedURLForUpload({ name: updatedFileName })
      // await axios.put(response.url, updatedFile, {
      //   headers: { 'Content-Type': 'image/png' },
      // })
      // await api.updateUploadFile({ name: updatedFileName })
    } catch (err) {
      console.log({ err })
    }
  }

  const addImageToCanvas = async url => {
    const image = await loadImageFromURL(url)
    // @ts-ignore
    let frameParams = { width: image.width, height: image.height }
    editor.handlers.frameHandler.update(frameParams)

    const options = {
      type: ObjectType.BAZAART_STICKER,
      centerPoint: {
        x: 0.5,
        y: 0.0,
      },
      sizeOnCanvas: {
        width: 1,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      absoluteRotation: 0,
      metadata: { src: url },
    }

    editor.handlers.objectsHandler.add(options).then(x => {
      const obj = editor.handlers.objectsHandler.getDefaultStaticImageObject()
      obj.center()
      editor.handlers.objectsHandler.deselect()
      // editor.handlers.objectsHandler.scale("fit")
      editor.handlers.objectsHandler.removeBg(obj)
      editor.handlers.objectsHandler.updateActive({ selectable: false }, obj)
    })
  }

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDropFiles(e.target.files)
  }

  const handleInputFileRefClick = () => {
    inputFileRef.current?.click()
  }

  const expandParticles = () => {
    globalThis.startExpanding = true
  }

  const drawParticles = () => {
    let radius = 48
    let particleSize = 7
    let particleVelocity = 1.6
    let particleRandomVelocity = 0.5
    let particleMultiplier = 2.2
    let iteration = 0
    let expendIteration = 60 * 3

    let radiusSqr = radius * radius
    let canvas = document.querySelector('#magic-canvas-animation') as HTMLCanvasElement
    let ctx = canvas.getContext('2d')

    // (onresize = function(){
    canvas.width = canvas.clientWidth
    canvas.height = canvas.clientHeight
    ctx.setTransform(1, 0, 0, 1, canvas.width / 2, canvas.height / 2)
    // })();

    let particles = (function () {
      var max = (1 << 16) - 1,
        length = 0,
        nextIndex = 0,
        map = new Uint16Array(max),
        x = new Float32Array(max),
        y = new Float32Array(max),
        vx = new Float32Array(max),
        vy = new Float32Array(max),
        life = new Uint16Array(max),
        startLife = new Uint16Array(max),
        each = function (fn) {
          for (var i = 0; i < length; i++) {
            fn(map[i], i)
          }
        },
        remove = function (i) {
          length = length >= 0 ? length - 1 : 0
          map[i] = map[length]
        }
      return {
        create: function (conf) {
          x[nextIndex] = conf.x || 0
          y[nextIndex] = conf.y || 0
          vx[nextIndex] = conf.vx || 0
          vy[nextIndex] = conf.vy || 0
          life[nextIndex] = conf.life || 0
          startLife[nextIndex] = conf.life
          map[length] = nextIndex
          length = length === max ? 0 : length + 1
          nextIndex = nextIndex === max ? 0 : nextIndex + 1
        },
        update: function () {
          each(function (i, id) {
            x[i] += vx[i]
            y[i] += vy[i]
            var modifier = globalThis.startExpanding ? i / 10 : 1
            var d = x[i] * x[i] + y[i] * y[i],
              dx = (x[i] * (1 + radius / d)) / 1000 / modifier,
              dy = (y[i] * (1 + radius / d)) / 1000 / modifier
            vx[i] += d < radiusSqr ? dx : -dx
            vy[i] += d < radiusSqr ? dy : -dy
            if (--life[i] < 0) remove(id)
          })
        },
        draw: function () {
          ctx.fillStyle = '#FFFFFF'
          ctx.globalCompositeOperation = 'screen'
          ctx.lineWidth = 2
          ctx.strokeStyle = '#FFFFFF'

          each(function (i) {
            var f = life[i] / startLife[i]
            const alpha = (f *= globalThis.startExpanding ? 1 - iteration / expendIteration : 1)
            ctx.globalAlpha = alpha
            ctx.beginPath()
            ctx.arc(x[i], y[i], particleSize * (1 - f), 0, Math.PI * 2)
            ctx.fill()
          })
        },
      }
    })()

    let loop = function () {
      ctx.save()
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.restore()
      if (!globalThis.startExpanding) {
        for (var i = 0; i < particleMultiplier; i++) {
          var a = Math.random() * Math.PI * 2,
            cos = Math.cos(a),
            sin = Math.sin(a)
          particles.create({
            x: cos * radius,
            y: sin * radius,
            vx: -sin * particleVelocity + (Math.random() - 0.5) * 2 * particleRandomVelocity,
            vy: cos * particleVelocity + (Math.random() - 0.5) * 2 * particleRandomVelocity,
            life: 100,
          })
        }
      }

      if (globalThis.startExpanding) {
        radius *= 1.1
        radiusSqr = radius * radius
        iteration++
      }
      particles.update()
      particles.draw()

      if (iteration == expendIteration) {
        setLoaded(true)
        editor.handlers.objectsHandler.deselect()
        return
      }

      requestAnimationFrame(loop)
    }

    requestAnimationFrame(loop)
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: '#ffffff',
      }}
    >
      {/* MINIBIS: hides it becasue we don't want to show those for now */}
      {/* <Navbar /> */}
      <div style={{ display: 'flex', flex: 1 }}>
        {/* <Panels /> */}
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
          <div style={{ flex: 1, display: 'flex', padding: '1px', backgroundImage: `url(${emptyFramBG})` }}>
            <Editor config={editorConfig} />
          </div>
          {loaded ? (
            <></>
          ) : (
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <canvas id="magic-canvas-animation"></canvas>
            </div>
          )}
          {uploading || (uploads && uploads.length > 0) ? (
            <></>
          ) : (
            <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
              <DropZone handleDropFiles={handleDropFiles} isParentOpen={true}>
                <div
                  style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}
                >
                  <div
                    style={{
                      padding: '2rem 2rem',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '7px',
                    }}
                  >
                    <div
                      className={css({
                        display: 'flex',

                        cursor: 'pointer',

                        textAlign: 'center',
                        color: '#fff',
                        backgroundColor: '#FF2453',
                        borderRadius: '8px',
                        fontSize: '20px',
                        fontWeight: '700',
                        padding: '0.6em 1.4em',
                        letterSpacing: '-0.025em',
                        textDecoration: 'none !important',
                        ':hover': {
                          cursor: 'pointer',
                          backgroundColor: '#FF2453',
                        },
                      })}
                      onClick={handleInputFileRefClick}
                    >
                      <span>+ Upload file</span>
                    </div>
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      or drop an image
                    </span>
                    <input
                      onChange={handleFileInput}
                      type="file"
                      id="file"
                      ref={inputFileRef}
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>
              </DropZone>
            </div>
          )}
          {/* <Footer /> */}
        </div>
        {/* <Toolbox /> */}
      </div>
      <div
        style={{
          display: loaded ? 'flex' : 'none',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '0.5rem',
          position: 'absolute',
          width: '100%',
          background: '#ffffff',
          bottom: 0,
        }}
      >
        <span style={{ textAlign: 'center', color: 'rgba(60, 61, 66, 0.4)', fontSize: '12px' }}>
          Don't forget to download your files. They will be discarded automatically after 60 minutes.
        </span>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '1.5rem', marginTop: '0.75rem' }}>
          <ExportImage />
          <div
            className={css({
              cursor: 'pointer',
              display: 'inline-block',
              textAlign: 'center',
              color: '#3C3D42',
              backgroundColor: '#fff',
              borderRadius: '8px',
              borderStyle: 'solid',
              borderColor: '#E0DFE4',
              borderWidth: '1px',
              fontSize: '14px',
              padding: '0.6em 1.4em',
              letterSpacing: '-0.025em',
              textDecoration: 'none !important',
            })}
            onClick={() => window.location.reload()}
          >
            <span>Start Over</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Magic
