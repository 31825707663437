import React from 'react'

function InspectorArrowOpen({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.01964 8.5L11.8263 4.34587C12.2075 3.92987 12.2075 3.29151 11.8263 2.87551C11.395 2.40484 10.6531 2.40484 10.2217 2.87551L5.80568 7.69465C5.60996 7.90825 5.5 8.19794 5.5 8.5C5.5 8.80206 5.60996 9.09175 5.80568 9.30535L10.2217 14.1245C10.6531 14.5952 11.395 14.5952 11.8263 14.1245C12.2075 13.7085 12.2075 13.0701 11.8263 12.6541L8.01964 8.5Z" fill="#7A7A7E"/>
    </svg>

  )
}

export default InspectorArrowOpen
