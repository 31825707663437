import { useEditorContext } from '@/scenes/engine'
import { useEffect, useRef, useState } from 'react'
import { ThemeProvider } from 'baseui'
import { lightTheme } from '@/customTheme'
import HeadingInspector from '@/components/HeadingInspector'
import AutoScroll from '@/components/AutoScroll'
import GridCustom from '@/components/GridCustom'
import { useSelector } from 'react-redux'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import Blending from './Blending'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useTranslation } from 'react-i18next'

function Filter({ isOpen, setIsOpen, filterObject = null, filterActiveId, setFilterActiveId }) {
  // const [filterObject, setFilterObject] = useState(null)
  const [filteredPacksMapped, setFilteredPacksMapped] = useState([])
  const listBackground = useRef(null)
  const [filterGroupByCategory, setFilterGroupByCategory] = useState({})
  const filterPacks = useSelector(selectImageElements)
  const { editor } = useEditorContext()

  const [preValue, setPreValue] = useState(1)
  const [preFilter, setPreFilter] = useState('')
  const [preFilters, setPreFilters] = useState([])
  const hasPremium = useSelector(selectUserIsPremium)
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  // useEffect(() => {
  //   setFilteredPacksMapped([])
  //   setFilterObject(background ? background : activeObject)
  // }, [background, activeObject])

  useEffect(() => {
    let res = {}
    filteredPacksMapped.forEach(p => {
      if (!res[p.source_platform]) {
        res[p.source_platform] = [p]
      } else {
        res[p.source_platform] = [...res[p.source_platform], p]
      }
    })
    setFilterGroupByCategory(res)
  }, [filteredPacksMapped])

  useEffect(() => {
    setPreValue(filterObject?.filterIntensity ?? 1)
    setPreFilters(filterObject?.filters ?? [])
    setPreFilter(filterObject?.filter ?? '')
    setFilterActiveId(filterObject?.filter ?? 'Normal')
  }, [filterObject])

  useEffect(() => {
    if (!filterObject) {
      return
    }
    getImageWithFilter()
    return () => {
      setFilteredPacksMapped([])
    }
  }, [filterObject])

  const onItemSelected = async (filter, isIntensity = false, isFinal = false) => {
    if (!filter.source_platform) {
      if (filterObject.filter === `${filter.description}`) {
        return
      }
      // editor.handlers.transactionHandler.save();
      let sliderOptions: any = { isSliderUpdate: isFinal }
      if (isFinal) {
        sliderOptions = {
          isSliderUpdate: isFinal,
          property: ['filterIntensity', 'filter', 'filters'],
          value: [preValue, preFilter, preFilters],
        }
        setPreValue(filterObject.filterIntensity)
        setPreFilters(filterObject.filters)
        setPreFilter(filterObject.filter)
      }
      editor.handlers.objectsHandler.updateActive(
        { filterIntensity: filterObject.filterIntensity },
        filterObject,
        sliderOptions
      )
      filterObject.filters = []
      filterObject.filter = `${filter.description}`
      await editor.canvasImageRenderer.render(filterObject, filterPacks)
    } else {
      const isDifferentFilter = !isIntensity && filterObject.filter !== `${filter.id}_${filter.store}`
      if (isDifferentFilter) {
        filterObject.filterIntensity = 1
        filter.filterIntensity = 1
      }
      const isItensityChanged = !!filter.filterIntensity && preValue !== filter.filterIntensity
      if (!isDifferentFilter && !isItensityChanged) {
        return
      }
      let sliderOptions: any = { isSliderUpdate: isFinal }
      if (isFinal) {
        sliderOptions = {
          isSliderUpdate: isFinal,
          property: ['filterIntensity', 'filter', 'filters'],
          value: [preValue, preFilter, preFilters],
        }
        setPreValue(filterObject.filterIntensity)
        setPreFilters(filterObject.filters)
        setPreFilter(filterObject.filter)
      }
      editor.handlers.objectsHandler.updateActive(
        { filterIntensity: filterObject.filterIntensity },
        filterObject,
        sliderOptions
      )
      filterObject.filter = `${filter.id}_${filter.store}`
      filterObject.filterIntensity = filter.filterIntensity
      await editor.canvasImageRenderer.render(filterObject, filterPacks)
      if (!isIntensity && isFinal) {
        setPreFilters(filterObject.filters)
        setPreFilter(filterObject.filter)
      }
    }
  }

  const getImageWithFilter = () => {
    // const staticCanvas = new fabric.StaticCanvas(null, { width: 200, height: 200 })
    // let latestImageUrl = filterObject._originalElement?.currentSrc
    //   ? filterObject._originalElement.currentSrc
    //   : await MediaImageRepository.getInstance().getImage(
    //       filterObject.id,
    //       filterObject.layerAssetStateId,
    //       MediaImageType.thumbnail
    //     )
    // if (!latestImageUrl) {
    //   return
    // }
    // const img: any = await loadImageFromURL(latestImageUrl)
    // let scaleFactor = Math.max(200 / img.width, 200 / img.height)

    let res = filterPacks.map(filter => {
      // const fabricImage: any = new fabric.StaticImage(img, {
      //   scaleX: scaleFactor,
      //   scaleY: scaleFactor,
      // })
      // fabricImage.useTextureOf(filterObject)
      // fabricImage.filter = `${filter.id}_${filter.store}`
      // fabricImage.filterIntensity = 1.0
      // await editor.canvasImageRenderer.render(fabricImage, filterPacks)
      // staticCanvas.add(fabricImage)
      // fabricImage.center()
      // const filteredImage = staticCanvas.toDataURL({ format: 'image/png', quality: 0 })
      if (filterObject.filter === `${filter.id}_${filter.store}`) {
        setFilterActiveId(filterObject.filter)
      }

      return {
        ...filter,
        filterPacks: filterPacks,
        filterIntensity:
          filterObject.filter === `${filter.id}_${filter.store}` && filterObject.filterIntensity
            ? filterObject.filterIntensity
            : 1,
      }
    })
    setFilteredPacksMapped(res)
  }

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          width: '340px',
          overflow: 'hidden',
          position: 'absolute',
          top: '0px',
          bottom: '16px',
          right: isOpen ? '0px' : '-356px',
          borderRadius: '12px',
          background: '#fff',
          zIndex: 1,
          height: '100%',
        }}
        id="wrap-filter-panel"
      >
        <HeadingInspector
          hasBoxShadow={isBoxShadow}
          hasNavigation={true}
          handleNavigation={() => {
            let wrapFilterPanel = document.getElementById('wrap-filter-panel')
            if (wrapFilterPanel) {
              wrapFilterPanel.style.right = '-356px'
            }
          }}
          title={t("Filter")}
        ></HeadingInspector>

        <AutoScroll
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
          }}
          ref={listBackground}
          style={{ marginTop: 0 }}>
          <>
            <Blending
              fitlerActiveId={filterActiveId}
              setFilterActiveId={setFilterActiveId}
              filterObject={filterObject}
            />
            {filterGroupByCategory &&
              Object.keys(filterGroupByCategory).map((category, index) => (
                <GridCustom
                  itemsPerLine={4}
                  handleSelectItem={onItemSelected}
                  listItem={filterGroupByCategory[category]}
                  name={category}
                  seeAll={true}
                  activeIdExternal={filterActiveId}
                  setActiveIdExternal={setFilterActiveId}
                  filterObject={filterObject}
                  hasPremium={hasPremium}
                />
              ))}
          </>
        </AutoScroll>
      </div>
    </ThemeProvider>
  )
}

export default Filter
